import packagesService from "../../../services/packages.service";
import AddPackage from "./AddPackage.vue";
import DeletePackage from "./DeletePackage.vue";

export default {
	name: "Packages",
	data() {
		return {
			isCustomer: false,
			packages: [],
			tableFields: [
				{ key: "name", label: "Paket Adı", _style: "min-width:200px" },
                { key: "price", label: "Fiyat", _style: "min-width:200px" },
				{ key: "companyCount", label: "Firma Sayısı", _style: "min-width:200px" },
				{ key: "productCount", label: "Ürün Sayısı", _style: "min-width:200px" },
				{
					key: "actions",
					label: "",
					_style: "width:1%",
					sorter: false,
					filter: false,
				},
			],
			details: [],
			listing: true,
			activePage: 1,
			totalNumberOfPages: 1,
			newItem: true,
			addItemModal: false,
			emptyItem: {
				name: null,
				price: null,
				productCount: null
			},
			selectedItems: []
		};
	},
	components: {
        AddPackage,
        DeletePackage
	},
	async mounted() {
		try {
			await this.loadItems();
		} catch (err) {
			console.error("error:", err);
		}
	},
	methods: {
		async paginationChage(e) {
			this.itemsPerPage = e;
			await this.loadItems();
		},
		async loadItems(params) {
			try {
                const res = await packagesService.get({});
                this.packages = res.data.results.map(x => ({
					...x
				}));
                /*
				this.allItemsSelected = false;
				const res = await WorkListService.get({
					page: this.activePage,
					skip: (this.activePage - 1) * this.itemsPerPage,
					limit: this.itemsPerPage,
					company: this.companyId,
					...params
				});
				this.worklists = res.data.results;
				this.totalNumberOfPages = res.data.pageCount;
				this.worklists.forEach((x) => {
					x.date = moment(x.date).format("DD/MM/YYYY");
				});
                */
			} catch (err) {
				console.error("err:", err);
			}
		},
		getBadge(active) {
			return active ? "success" : "danger";
		},
		async serverSearch(e) {
			this.activePage = 1;
			const active = ["", "true", "false"];
			await this.loadItems({ name: this.search.name, active: active[this.search.active], date: this.search.date })
		},
		getSelectedItems() {
			this.selectedItems = this.worklists.filter((x) => {
				if (x._selected) return x._id;
			});
		},
		addItem() {
			console.log(this.$refs);
			this.$refs.addModal.show({
				loadItemsFunc: this.loadItems, item: { ...this.emptyItem }
			});
		},
		editItem(item) {
			const existingItem = {...item};
			this.$refs.addModal.show({
				loadItemsFunc: this.loadItems, item: existingItem
			});
		},
		deleteItem(item) {
			this.$refs.deleteModal.show({ loadItemsFunc: this.loadItems, item: { name: item.name, _id: item._id } });
		},
	},
};
