import CrudService from './crud.service';
import axios from "axios";
import authHeader from "./auth-header";
class ProductService extends CrudService {
  constructor(){
    super('products');
  }
  async approve(data, type) {
		return axios.put(`${this.apiUrl}/${this.endpoint}/${type}/${data._id}`, data, {
      params: {
				application: this.application
			}, 
      headers: authHeader()
		});
	}
}

export default new ProductService();