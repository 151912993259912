<template>
  <input ref="inputRef" type="text" class="form-control" />
</template>

<script>
import useCurrencyInput from "vue-currency-input";
import { computed, watch } from "@vue/composition-api";

export default {
  name: "CurrencyInput",
  props: {
    value: {
      type: Number,
      default: null,
    }
  },
  setup(props) {
    const { inputRef, setValue } = useCurrencyInput({
      currency: "TRY",
      currencyDisplay: "symbol",
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: false,
      autoDecimalDigits: false,
      autoSign: true,
      useGrouping: true,
      accountingSign: false,
    });

    watch(
      () => props.value,
      (value) => {
        setValue(value);
      }
    );

    return { inputRef };
  },
};
</script>