import advertorialService from "../../../services/advertorial.service";

export default {
	name: "BulkDeleteAdvertorial",
	data() {
		return {
			modalVisible: false,
			items: [],
			loadItemsFunc: Function
		};
	},
	methods: {
		show({ loadItemsFunc, items }) {
			this.items = [...items];
			this.loadItemsFunc = loadItemsFunc;
			this.modalVisible = true;
		},
		cancel() {
			this.item = {
				province: null,
				name: null
			};
			this.modalVisible = false;
		},
		async _delete() {
			try {
				await advertorialService.delete([...this.items]);
				await this.loadItemsFunc();
				this.modalVisible = false;
				this.$toast.success({
					title: 'Toplu Reklam Silme',
					message: 'Reklamlar başarıyla silindi'
				});
			} catch (err) {
				const message =
					(err.response && err.response.data) ||
					err.message ||
					err.toString() ||
					err.error;

				this.$toast.error({
					title: 'Toplu reklam silme',
					message: message.error || message
				});

				console.error('bulkDeleteAdvertorial/_delete error: ', err);
			}
		},
	},
}