
import crudservice from './crud.service';
import axios from "axios";
import authHeader from "./auth-header";
class CampaingService extends crudservice {
  constructor() {
    super('campaign');
  }

  delete(data) {
    return axios.delete(`${this.apiUrl}/${this.endpoint}`, {
      data, params: {
        application: this.application
      }, headers: authHeader()
    });
  }
}

export default new CampaingService();
