import districtService from "../../../services/district.service";

export default {
	name: "DeleteDistrict",
	data() {
		return {
			modalVisible: false,
			item: {},
			loadItemsFunc: Function
		};
	},
	methods: {
		show({ loadItemsFunc, item }) {
			this.item = { ...item };
			this.loadItemsFunc = loadItemsFunc;
			this.modalVisible = true;
		},
		cancel() {
			this.item = {
				province: null,
				name: null
			};
			this.modalVisible = false;
		},
		async _delete() {
			try {
				await districtService.delete([this.item._id]);
				await this.loadItemsFunc();
				this.$toast.success({
					title: 'İlçe silme',
					message: 'İlçe başarıyla silindi'
				});
				this.modalVisible = false;
			} catch (err) {
				console.error('DeleteDistrict/_delete error: ', err);
			}
		},
	},
}