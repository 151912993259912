import sectorService from "../../../services/sector.service";
import Multimedia from "../../../components/Multimedia/Multimedia.vue";
import Iconselector from "../../../components/IconSelector/IconSelector.vue";

export default {
	name: "AddSector",
	components: {
		Multimedia,
		Iconselector
	},
	data() {
		return {
			modalVisible: false,
			isLoading: false,
			item: {},
			sectorList: [],
			multimediaUrl: "",
		}
	},
	props: {
		loadItemsFunc: {
			type: Function,
			required: true,
		},
	},
	methods: {
		async show({ item }) {
			this.modalVisible = true;
			this.item = { ...item };
			console.log("item", item);
			if (this.item._id) {
				this.multimediaUrl = (item.multimedia) ? `${process.env.VUE_APP_API_URL}/multimedia/${item.multimedia}` : undefined;
				this.item.parentSector = this.item.parentSector.length > 0 ? { label: item.parentSector[0].name, value: item.parentSector[0]._id } : { label: "", value: "" };
			}
			await this.findSectors();
			this.$forceUpdate();
		},
		cancel() {
			this.modalVisible = false;
			this.errors.clear();
		},
		async save() {
			try {
				const isValid = await this.$validator.validateAll();
				if (!isValid) {
					return;
				}
				const item = { ...this.item };
				item.parent_id = item.parentSector ? item.parentSector.value : null;
				if (!item._id) {
					const res = await sectorService.post({
						...item,
						company: this.companyId,
					});
					await this.loadItemsFunc();
					this.$toast.success({
						title: 'Sektör ekleme',
						message: 'Yeni sektör başarıyla eklendi',
					});
					this.modalVisible = false;
					this.errors.clear();
				} else {
					const res = await sectorService.put(item._id, item);
					await this.loadItemsFunc();
					this.$toast.success({
						title: 'Sektör güncelleme',
						message: 'Sektör başarıyla güncellendi',
					});
					this.modalVisible = false;
					this.errors.clear();
				}
			} catch (err) {
				const message =
					(err.response && err.response.data) ||
					err.message ||
					err.toString() ||
					err.error;

				this.$toast.error({
					title: 'Sektör ekleme',
					message: message.error || message
				});

				console.error('addSector/save error: ', err);
			}
		},
		async findSectors(query) {
			this.isLoading = true;
			try {
				const res = await sectorService.get({
					page: 1,
					adminView: true,
					name: query
				});
				const sectors = res.data.results.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				this.sectorList = [...sectors];
			} catch (err) {
				throw err;
			}
			this.isLoading = false;
		},
		selectMultimedia(image, whereIs) {
			if (whereIs === "sectors") {
				this.item.multimedia = image.id;
				this.multimediaUrl = `${process.env.VUE_APP_API_URL}/multimedia/${image.id}`;
				this.$forceUpdate();
			}
		},
		openMultimedia() {
			this.$refs.mmodal.show('sectors', 0);
		},

	}
}