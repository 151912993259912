import Vue from "vue";
import Router from "vue-router";

import TheContainer from "../containers/TheContainer.vue";

import Dashboard from "../views/Dashboard.vue";

// Views
import Page404 from "../views/pages/Page404";
import Buildings from "../views/pages/Buildings/Buildings.vue";
import Companies from "../views/pages/Companies/Companies.vue";
import Sectors from "../views/pages/Sectors/Sectors.vue";
import RealEstates from "../views/pages/RealEstates/RealEstates.vue";
import Advertorials from "../views/pages/Advertorials/Advertorials.vue";
import Explores from "../views/pages/Explores/Explores.vue";
import Districts from "../views/pages/Districts/Districts.vue";
import Application from "../views/pages/Application/Application.vue";
import Codes from "../views/pages/Codes/Codes.vue";
import Products from "../views/pages/Products/Products.vue";
import Worklists from "../views/pages/Worklists/Worklists.vue";
import CustomerCompany from "../views/pages/Companies/CustomerCompany.vue";
import Packages from "../views/pages/Packages/Packages.vue";
import Users from "../views/pages/Users/Users.vue";
import Profile from "../views/pages/Profile/Profile.vue";

//components
import Campaigns from "../components/Campaigns/Campaigns.vue";
// Views/Auth
import Login from "../views/auth/Login";
import Register from "../views/auth/Register";

import VueJwtDecode from "vue-jwt-decode";
import moment from "moment";

Vue.prototype.moment = moment;

Vue.use(Router);

export const router = new Router({
  mode: "hash",
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/dashboard",
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "/buildings",
          component: Buildings,
          meta: { role: "admin" },
        },
        {
          path: "/companies",
          component: Companies,
          meta: { role: "admin" },
        },
        {
          path: "/sectors",
          component: Sectors,
          meta: { role: "admin" },
        },
        {
          path: "/real-estates",
          component: RealEstates,
          meta: { role: "admin" },
        },
        {
          path: "/campaigns",
          component: Campaigns,
          meta: { role: "admin" },
        },
        {
          path: "/advertorials",
          component: Advertorials,
          meta: { role: "admin" },
        },
        {
          path: "/explores",
          component: Explores,
          meta: { role: "admin" },
        },
        {
          path: "/districts",
          component: Districts,
          meta: { role: "admin" },
        },
        {
          path: "/application",
          component: Application,
          meta: { role: "admin" },
        },
        {
          path: "/codes",
          component: Codes,
          meta: { role: "admin" },
        },
        {
          path: "/products",
          component: Products,
          meta: { role: "admin" },
        },
        {
          path: "/worklists",
          component: Worklists,
          meta: { role: "admin" },
        },
        {
          path: "/packages",
          component: Packages,
          meta: { role: "admin" },
        },
        {
          path: "/customer-company",
          component: CustomerCompany,
          meta: { role: "customer" },
        },
        {
          path: "/customer-products",
          component: Products,
          meta: { role: ["customer", "subCustomer"] },
        },
        {
          path: "/customer-companies",
          component: Companies,
          meta: { role: ["customer", "subCustomer"] },
        },
        {
          path: "/customer-worklists",
          component: Worklists,
          meta: { role: ["customer", "subCustomer"] },
        },
        {
          path: "/users",
          component: Users,
          meta: { role: ["customer", "admin"] },
        },
        {
          path: "/profile",
          component: Profile,
          meta: { role: ["customer", "admin", "subCustomer"] },
        }
      ],
    },
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/register",
      component: Register,
    },
    {
      path: "*",
      name: '404',
      component: Page404,
    },
  ];
}

router.beforeEach((to, from, next) => {
  document.title = "Firma Rehberi Uygulaması";
  const publicPages = ["/login", "/register"];
  const authRequired = !publicPages.includes(to.path);
  const token = localStorage.getItem("token");
  let usr = {};
  try {
    usr = JSON.parse(localStorage.getItem("usr"));
  } catch (err) {
    console.error(err);
  }

  if (authRequired) {
    if (!token) return next("/login");

    try {
      const decoded = VueJwtDecode.decode(token);
      if (decoded.exp < moment().unix()) {
        localStorage.removeItem("token");
        return next("/login");
      }
    } catch (error) {
      console.error("error", error);
    }
  }

  if (to.meta.role) {
    if (Array.isArray(to.meta.role)) {
      if (!to.meta.role.includes(usr.role)) {
        return next({
          name: "404",
        });
      }
    } else if (to.meta.role !== usr.role) {
      return next({
        name: '404'
      });
    }
  }

  next();
});
