import { render, staticRenderFns } from "./AddCompany.html?vue&type=template&id=1ff4d8b0&"
import script from "./AddCompany.js?vue&type=script&lang=js&"
export * from "./AddCompany.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports