import ProductService from "../../../services/products.service";
import AddProduct from "./AddProduct.vue";
import DeleteProduct from "./DeleteProduct.vue";
import BulkDeleteProduct from "./BulkDeleteProduct.vue";
import CodeService from "../../../services/code.service";

import { numberWithGroupSeperator } from "../../../lib/utils";

export default {
	name: "Products",
	components: {
		AddProduct,
		DeleteProduct,
		BulkDeleteProduct
	},
	data() {
		return {
			isCustomer: false,
			products: [],
			tableFields: [
				{ key: "select", label: "" },
				{ key: "productId", label: "Ürün Id" },
				{ key: "name", label: "Ürün Adı" },
				{ key: "companyName", label: "Firma Adı" },
				{ key: "active", label: "Aktif" },
				{ key: "order", label: "Öncelik Sırası" },
				{ key: "price", label: "Fiyat" },
				{ key: "status", label: "Durum" },
				{
					key: "actions",
					label: "",
					_style: "width:1%",
					sorter: false,
					filter: false,
				},
			],
			details: [],
			itemsPerPage: 10,
			collapseDuration: 0,
			listing: true,
			tableFilter: {
				label: "Filtrele",
				placeholder: "Aranacak kelimeyi girin",
				external: true,
				lazy: true,
			},
			noItemsViewSettings: {
				noResults: "Filtrelenecek sonuç yok",
				noItems: "Görüntülenecek bilgi yok",
			},
			activePage: 1,
			totalNumberOfPages: 1,
			emptyItem: {
				name: "",
				description: "",
				price: 0,
				multimedia: [],
				category: [],
				url: "",
				active: true,
				order: 0,
				sector: null,
			},
			multimediaUrl: "",
			categories: [],
			sectors: [],
			addCodeModalVisible: false,
			categoryName: '',
			tableFilterText: '',
			itemsPerPageSettings: { label: "Sayı", values: [10, 20, 50, 100] },
			search: {
				name: '',
				active: 0,
				order: '',
				price: '',
				productId: ''
			},
			selectedItems: [],
			batchDeleteModal: false,
			allItemsSelected: false,
			companyId: null,
			waitListForApprove: false,
			role: '',
			selectedCategory: null
		};
	},
	async mounted() {
		try {
			this.role = this.$store.state.auth.usr.role;
			this.isCustomer = this.role === 'customer' || this.role === 'subCustomer';
			this.categories = await this.loadCodes(5);
			await this.loadItems();
		} catch (err) {
			console.error("error:", err);
		}
	},
	methods: {
		async selectCategory(selectedOption) {
			await this.serverSearch(null, selectedOption.value);
		},
		async removeCategory() {
			await this.serverSearch();
		},
		async loadItems(params) {
			try {
				this.selectedItems = [];
				const res = await ProductService.get({
					page: this.activePage,
					skip: (this.activePage - 1) * this.itemsPerPage,
					limit: this.itemsPerPage,
					company: this.companyId,
					...params
				});

				this.products = res.data.results.map(x => ({
					...x,
					companyName: x?.company?.name || '',
				}));
				this.totalNumberOfPages = res.data.pageCount;
			} catch (err) {
				console.error("err:", err);
			}
		},
		async loadCodes(codeType, query) {
			try {
				const resCode = await CodeService.get({ type: codeType, name: query });

				return resCode.data.map(x => ({
					value: x._id,
					label: x.name,
				}));
			} catch (error) {
				console.error("products/loadCodes:", error);
				return [];
			}
		},
		async findCategory(query) {
			this.categories = await this.loadCodes(5, query);
		},
		getBadge(active) {
			return active ? "success" : "danger";
		},
		getStatusBadge(status) {
			switch (status) {
				case 'waiting': return 'warning';
				case 'declined': return 'danger';
				case 'accepted': return 'success';
			}
		},
		addItem() {
			this.$refs.addModal.show({
				loadItemsFunc: this.loadItems, item: { ...this.emptyItem }
			});
		},
		editItem(item) {
			const existingItem = { ...this.emptyItem, ...item };
			this.$refs.addModal.show({
				loadItemsFunc: this.loadItems, item: existingItem
			});
		},
		async acceptItem(item) {
			if (item.status === 'waiting') {
				await ProductService.approve(item, 'accept');
				await this.serverSearch();
			}
		},
		async declineItem(item) {
			if (item.status === 'waiting') {
				await ProductService.approve(item, 'decline');
				await this.serverSearch();
			}
		},
		async deleteItem(item) {
			this.$refs.deleteModal.show({ loadItemsFunc: this.loadItems, item: { name: item.name, _id: item._id, company: item.company._id } });
		},
		async paginationChage(e) {
			this.itemsPerPage = e;
			this.allItemsSelected = false;
			await this.loadItems();
		},
		check(item) {
			const val = Boolean(item._selected);
			this.products = this.products.map(product => product._id === item._id ? ({ ...product, _selected: !val }) : product);
			this.getSelectedItems();
		},
		checkAll(checked) {
			this.products.forEach((item) => {
				this.$set(item, "_selected", checked);
			});
			this.getSelectedItems();
		},
		async serverSearch(e, selectedCategory) {
			this.activePage = 1;
			const active = ["", "true", "false"];
			await this.loadItems({ 
				name: this.search.name, 
				active: active[this.search.active], 
				order: this.search.order, 
				price: this.search.price, 
				productId: this.search.productId, 
				onlyWaitingApproved: this.waitListForApprove,
				category: selectedCategory
			});
		},
		showBatchDeleteModal() {
			this.batchDeleteModal = true;
		},
		getSelectedItems() {
			this.selectedItems = this.products.filter((x) => {
				if (x._selected) return x._id;
			});
		},
		bulkDeleteItems() {
			this.$refs.bulkDeleteModal.show({ loadItemsFunc: this.loadItems, items: this.selectedItems });
		},
		async getOnlyWaitingList(e) {
			this.waitListForApprove = e;
			await this.serverSearch();
		},
		numberWithGroupSeperator
	},
	watch: {
		tableFilterText: async function (n, o) {
			this.activePage = 1;
			await this.loadItems({ name: n });
		},
		selectedItems(n, o) {
			this.allItemsSelected = (n.length > 0 && (n.length === this.products.length));
		}
	},
	created() {
		this.$validator.extend(
			'secildi', {
			getMessage: field => `${field} seçiniz`,
			validate: (value) => {
				if (!value.value) return false;
				return true;
			}
		});
	},
};
