import draggable from 'vuedraggable'

export default {
    props: {
        images: Array,
        whereIs: String
    },
	data() {
		return {
            items: this.images
        };
	},
    components: {
        draggable
    },
    watch: { 
        immediate: true, 
        images: function(newVal, oldVal) {
            this.items = newVal;
        }
    },
	name: 'Gallery',
	methods: {
        getPath(image) {
            return `${process.env.VUE_APP_API_URL}/multimedia/${image}`
        },
        getComponentData() {
            return {
              on: {},
              attrs:{
                class: 'row',
                style: 'display: inherit'
              },
              props: {}
            };
        },
        onChange(e) {
            this.$emit('changedMultimedia', this.whereIs, this.items);
        },
        deleteItem(image) {
            this.items = this.items.filter(itm => itm !== image);
            this.$emit('changedMultimedia', this.whereIs, this.items);
        }
    }
}