import advertorialService from "../../../services/advertorial.service";
import sectorService from "../../../services/sector.service";
import companyService from "../../../services/company.service";

import Multimedia from "../../../components/Multimedia/Multimedia.vue";
import moment from "moment";

export default {
	name: "AddAdvertorial",
	data() {
		return {
			modalVisible: false,
			item: {},
			loadItemsFunc: Function,
			multimediaUrl: '',
			sectors: [],
			companies: []
		};
	},
	components: {
		Multimedia,
	},
	async mounted() {
		try {
			await this.loadCompanies();
			await this.findSectors();
		} catch (err) {
			throw err;
		}
	},
	methods: {
		show({ loadItemsFunc, item }) {
			if (item._id) {
				if (item.sector) {
					item.sector = { label: item.sector.name, value: item.sector._id };
					this.findSectors(item.sector.label);
				}
				if (item.company) {
					item.company = { label: item.company.name, value: item.company._id };
					this.findCompany(item.company.label);
				}
				if (item.multimedia) {
					this.multimediaUrl = `${process.env.VUE_APP_API_URL}/multimedia/${item.multimedia}`;
				} else {
					this.multimediaUrl = "";
				}
			}
			this.modalVisible = true;
			this.loadItemsFunc = loadItemsFunc;
			this.item = { ...item }
		},
		async loadCompanies() {
			try {
				const res = await companyService.get({
					adminView: true,
					page: 1,
				});
				const companies = res.data.results.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				this.companies = companies;
			} catch (error) {
				console.error("Explores/loadCompanies error: ", error);
				return [];
			}
		},
		async findCompany(query) {
			this.isLoading = true;
			try {
				const res = await companyService.get({
					page: 1,
					adminView: true,
					name: query
				});
				const companies = res.data.results.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				this.companies = [...companies];
			} catch (err) {
				throw err;
			}
			this.isLoading = false;
		},
		cancel() {
			this.item = {
				province: null,
				name: null
			};
			this.modalVisible = false;
		},
		async save() {
			const isValid = await this.$validator.validateAll();
			if (!isValid) {
				return;
			}

			const item = { ...this.item };
			if (!item.multimedia && item._id) {
				this.$toast.error({
					title: 'Reklam ekleme',
					message: 'Resim seçiniz'
				});
				return;
			}

			item.start_date = moment(
				item.start_date,
				"DD/MM/YYYY"
			).toString();
			item.end_date = moment(
				item.end_date,
				"DD/MM/YYYY"
			).toString();
			if (item.sector) {
				item.sector = item.sector.value;
			}
			if (item.company) {
				item.company = item.company.value;
			}

			try {
				if (!this.item._id) {
					await advertorialService.post(item)
					this.$toast.success({
						title: 'Reklam ekleme',
						message: 'Reklam başarıyla eklendi'
					});
				} else {
					await advertorialService.put(item._id, item);
					this.$toast.success({
						title: 'Reklam düzenleme',
						message: 'Reklam başarıyla düzenlendi'
					});
				}
				await this.loadItemsFunc();
				this.modalVisible = false;
			} catch (err) {
				const message =
					(err.response && err.response.data) ||
					err.message ||
					err.toString() ||
					err.error;

				this.$toast.error({
					title: 'Reklam işlemleri',
					message: message.error || message
				});

				console.error('addAdvertorial/_delete error: ', err);
			}
		},
		openMultimedia() {
			this.$refs.mmodal.show("advertorialsMedia", this.item._id);
		},
		selectMultimedia(image, whereIs) {
			if (whereIs === "advertorialsMedia") {
				this.item.multimedia = image.id;
				this.multimediaUrl = `${process.env.VUE_APP_API_URL}/multimedia/${image.id}`;
				this.$forceUpdate();
			}
		},
		async findSectors(query) {
			this.isLoading = true;
			try {
				const res = await sectorService.get({
					page: 1,
					adminView: true,
					name: query
				});
				const sectors = res.data.results.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				this.sectors = [...sectors];
			} catch (err) {
				throw err;
			}
			this.isLoading = false;
		},
	},
	created() {
		this.$validator.extend(
			'secildi', {
			getMessage: field => `${field} seçiniz`,
			validate: (value) => {
				if (parseInt(value) === 0) return false;
				return true;
			}
		});
	},
}