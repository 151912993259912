import CrudService from './crud.service';
import axios from "axios";
import authHeader from "./auth-header";
class UserService extends CrudService {
  constructor() {
    super('user');
  }

  list(params) {
    return axios.get(`${this.apiUrl}/${this.endpoint}/list`, {
      params: {
        ...params,
        application: this.application,
      }, headers: authHeader()
    });
  }

  createSubCustomer(data) {
    return axios.post(`${this.apiUrl}/${this.endpoint}/createSubCustomer`, { ...data }, {
      headers: authHeader()
    });
  }
  
  createUserForAdmin(data) {
    return axios.post(`${this.apiUrl}/${this.endpoint}/createUserForAdmin`, { ...data }, {
      headers: authHeader()
    });
  }

  updateSubCustomer(id, data) {
    return axios.put(`${this.apiUrl}/${this.endpoint}/updateSubCustomer/${id}`, { ...data }, {
      headers: authHeader()
    });
  }

  deleteSubCustomer(data) {
    return axios.delete(`${this.apiUrl}/${this.endpoint}/deleteSubCustomer`, {
      data, headers: authHeader()
    });
  }
}

export default new UserService();