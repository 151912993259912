import AdvertorialService from "../../../services/advertorial.service";
import AddAdvertorial from "./AddAdvertorial.vue";
import DeleteAdvertorial from "./DeleteAdvertorial.vue";
import BulkDeleteAdvertorial from "./BulkDeleteAdvertorial.vue";

import moment from "moment";

export default {
	name: "Advertorials",
	data() {
		return {
			tableFields: [
				{ key: "select", label: "" },
				{ key: "name", label: "Reklam Adı", _style: "min-width:200px" },
				{ key: "companyName", label: "Firma Adı", _style: "min-width:200px" },
				{ key: "start_date", label: "Başlangıç Tarihi", _style: "min-width:200px" },
				{ key: "end_date", label: "Bitiş Tarihi", _style: "min-width:200px" },
				{
					key: "actions",
					label: "",
					_style: "width:1%",
					sorter: false,
					filter: false,
					external: true,
					lazy: true,
				},
			],
			advertorials: [],
			itemsPerPage: 10,
			collapseDuration: 0,
			tableFilter: {
				label: "Filtrele",
				placeholder: "Aranacak kelimeyi girin",
			},
			noItemsViewSettings: {
				noResults: "Filtrelenecek sonuç yok",
				noItems: "Görüntülenecek bilgi yok",
			},
			itemsPerPageSettings: { label: "Sayı", values: [10, 20, 50, 100] },
			activePage: 1,
			totalNumberOfPages: 1,
			newItem: true,
			addItemModal: false,
			emptyItem: {
				name: null,
				start_date: null,
				end_date: null,
				multimedia: null,
				sector: null,
				url: null,
				company: null
			},
			selectedItems: [],
			allItemsSelected: false,
			search: {
				name: '',
			},
			tableFilterText: '',
		};
	},
	components: {
		AddAdvertorial,
		DeleteAdvertorial,
		BulkDeleteAdvertorial
	},
	async mounted() {
		try {
			await this.loadItems();
		} catch (err) {
			console.error("error:", err);
		}
	},
	methods: {
		async paginationChage(e) {
			this.activePage = 1;
			this.itemsPerPage = e;
			await this.loadItems();
		},
		async loadItems(params) {
			try {
				this.selectedItems = [];
				const res = await AdvertorialService.get({
					page: this.activePage,
					skip: (this.activePage - 1) * this.itemsPerPage,
					limit: this.itemsPerPage,
					...params
				});
				this.totalNumberOfPages = res.data.pageCount;
				const advertorials = res.data.results;
				this.advertorials = advertorials.map((x) => ({
					_id: x._id,
					name: x.name,
					start_date: moment(x.start_date).format("DD/MM/YYYY"),
					end_date: moment(x.end_date).format("DD/MM/YYYY"),
					multimedia: x.multimedia,
					sector: x.sector,
					url: x.url,
					company: x.company,
					companyName: x.company ? x.company.name : '',
					_classes: moment(new Date()).diff(moment(x.end_date)) > 0 ? 'table-danger' : ''
				}));
				console.log(this.advertorials);
			} catch (err) {
				console.error("err:", err);
			}
		},
		async deleteItem(item) {
			this.$refs.deleteModal.show({ loadItemsFunc: this.loadItems, item: { name: item.name, _id: item._id } });
		},
		check(item) {
			const val = Boolean(item._selected);
			this.advertorials = this.advertorials.map(advertorial => advertorial._id === item._id ? ({ ...advertorial, _selected: !val }) : advertorial);
			this.getSelectedItems();
		},
		checkAll(checked) {
			this.advertorials.forEach((item) => {
				this.$set(item, "_selected", checked);
			});
			this.getSelectedItems();
		},
		getSelectedItems() {
			this.selectedItems = this.advertorials.filter((x) => {
				if (x._selected) return x._id;
			});
		},
		async serverSearch(e) {
			this.activePage = 1;
			await this.loadItems({ name: this.search.name, companyName: this.search.companyName })
		},
		addItem() {
			this.$refs.addModal.show({
				loadItemsFunc: this.loadItems, item: { ...this.emptyItem }
			});
		},
		editItem(item) {
			const existingItem = { ...this.emptyItem, ...item };
			this.$refs.addModal.show({
				loadItemsFunc: this.loadItems, item: existingItem
			});
		},
		bulkDeleteItems() {
			this.$refs.bulkDeleteModal.show({ loadItemsFunc: this.loadItems, items: this.selectedItems });
		}
	},
	watch: {
		tableFilterText: async function (n, o) {
			this.activePage = 1;
			await this.loadItems({ name: n });
		},
		selectedItems(n, o) {
			this.allItemsSelected = (n.length > 0 && (n.length === this.advertorials.length));
		}
	}
}