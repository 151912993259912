import MultimediaService from "../../services/multimedia.service";

export default {
	props: ['aspectRatio'],
	data() {
		return {
			multimediaModal: false,
			images: [],
			file: "",
			loading: false,
			whereIs: "",
			whereIsId: null
		};
	},
	name: "Multimedia",
	methods: {
		async deleteItem(image) {
			if (confirm('Seçili resim sistemden tamamen silinecek. Onaylıyor musunuz?')) {
				await MultimediaService.delete({id: image.id});
				this.loading = true;
				await this.loadImages();
				this.loading = false;
			}
		},
		selectImage(image) {
			this.$emit("selectMultimedia", image, this.whereIs);
			this.multimediaModal = false;
		},
		async show(whereIs, whereIsId) {
			this.whereIs = whereIs;
			this.whereIsId = whereIsId;
			this.loading = true;
			this.multimediaModal = true;
			await this.loadImages();
			this.loading = false;
		},
		checkAspectRatio(image) {
			const self = this;
			return new Promise((resolve, reject) => {
				if (self.aspectRatio && parseFloat(self.aspectRatio, 10) > 0) {
					let img = new Image()
					img.src = window.URL.createObjectURL(image);
					img.onload = () => {
						const ratio = (img.width / img.height);
						// ratio = 7.8 - aspecRatio = 7
						if (parseFloat(self.aspectRatio, 10) !== Math.floor(ratio)) {
							reject('Aspect ratio uyumsuz. Lütfen aspect ratio değerine uygun bir resim seçiniz. Aspect ratio:' + self.aspectRatio);
							return;
						} else {
							resolve();
							return;
						}
					}
				}
				resolve();
			});
		},
		async handleFileUpload() {
			try {
				for (const file of this.$refs.file.files) {
					await this.checkAspectRatio(file);
				}
			} catch (err) {
				this.loading = false;
				this.file = '';
				this.$refs.file.value = '';
				alert(err);
				return;
			}
			this.file = this.$refs.file.files;
		},
		async submitFile() {
			if (this.file !== "") {
				this.loading = true;
				const { data: files } = await MultimediaService.fileUpload(this.file, this.whereIsId, this.whereIs);
				for (let file of files) {
					const image = {
						url: `${process.env.VUE_APP_API_URL}/multimedia/${file._id}`,
						id: file._id,
					};
					this.$emit("selectMultimedia", image, this.whereIs);
				}
				// await this.loadImages();
				this.loading = false;
				this.file = '';
				this.$refs.file.value = '';
				this.multimediaModal = false;
			} else {
				alert("Lütfen dosya seçiniz.");
			}
		},
		async loadImages() {
			let res = await MultimediaService.get({whereIsId: this.whereIsId, whereIs: this.whereIs});
			let _res = [];
			for (let r of res.data) {
				_res.push({
					url: `${process.env.VUE_APP_API_URL}/multimedia/${r._id}`,
					id: r._id,
				});
			}
			this.images = [ ..._res ];
		},
	},
	async mounted() {
		try {
			await this.loadImages();
		} catch (err) {
			console.error("err", err);
		}
	},
};
