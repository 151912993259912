import userService from "../../../services/user.service";
import companyService from "../../../services/company.service";
import packagesService from "../../../services/packages.service";

export default {
	name: "AddUser",
	data() {
		return {
			isCustomer: false,
			isAdmin: false,
			isLoading: false,
			modalVisible: false,
			item: {
				name: "",
				surname: "",
				email: "",
				password: "",
				telephone: "",
				avatar_path: "",
				role: "",
				company: ""
			},
			loadItemsFunc: Function,
			roles: [
				{ value: null, label: "Rol seçiniz" },
				{ value: 'admin', label: 'Yönetici' },
				{ value: 'customer', label: 'Müşteri' },
				{ value: 'subCustomer', label: 'Alt Kullanıcı' },
			],
			packages: [],
			users: []
		};
	},
	async mounted() {
		try {
			this.isCustomer = this.$store.state.auth.usr.role === 'customer';
			this.isAdmin = this.$store.state.auth.usr.role === 'admin';
			await this.findUsers('');
		} catch (err) {
			console.error("addUser/mounted error:", err)
		}
	},

	methods: {
		acceptTelephoneNumber() {
			const x = this.item.telephone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
			this.item.telephone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
		},
		async show({ loadItemsFunc, item }) {
			this.item = { ...item }
			this.modalVisible = true;
			this.loadItemsFunc = loadItemsFunc;
			if (this.isAdmin) {
				const res = await packagesService.get({});
                this.packages = res.data.results.map(x => ({
					value: x._id,
					label: x.name
				}));
			}
		},
		cancel() {
			this.item = {};
			this.modalVisible = false;
		},
		async findUsers(query) {
			this.isLoading = true;
			try {
				const res = await userService.list({
					page: 1,
					adminView: true,
					name: query,
					role: 'customer'
				});
				const users = res.data.results.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				this.users = [ ...users ];
			} catch (err) {
				throw err;
			}
			this.isLoading = false;
		},
		async save() {
			try {
				const isValid = await this.$validator.validateAll();
				if (!isValid) {
					console.error(this.$validator.errors.all());
					return;
				}
				const item = { ...this.item };
				if (item.company) {
					item.company = item.company?.value;
				}
				if (item.parentUser) {
					item.parentUser = item.parentUser.value;
				}
				if (!this.item._id) {
					if (!this.item.password) {
						this.$toast.error({
							title: 'Kullanıcı düzenleme',
							message: 'Lütfen şifre giriniz.'
						});
						return;
					}
					if (this.isAdmin) {
						await userService.createUserForAdmin({
							...item
						});
					} else {
						const res = await userService.createSubCustomer({
							...item
						});
					}
					await this.loadItemsFunc();
					this.$toast.success({
						title: 'Kullanıcı Ekleme',
						message: 'Kullanıcı başarıyla eklendi.'
					});
				} else {
					if (!this.item.password) {
						delete this.item.password;
					}
					if (this.isAdmin) {
						await userService.put(item._id, item);
					} else {
						const res = await userService.updateSubCustomer(item._id, item);
					}
					await this.loadItemsFunc();
					this.$toast.success({
						title: 'Kullanıcı Düzenleme',
						message: 'Kullanıcı başarıyla düzenlendi.'
					});
				}
				this.modalVisible = false;
			} catch (err) {
				const message =
					(err.response && err.response.data) ||
					err.message ||
					err.toString() ||
					err.error;

				this.$toast.error({
					title: 'Kullanıcı işlemleri',
					message: message.error || message
				});
				console.error('addUser/save error: ', err);
			}
		},
	},
}