import CrudService from './crud.service';
import axios from "axios";
import authHeader from "./auth-header";
class SectorService extends CrudService {
  constructor() {
    super('sector');
  }

  async delete(data) {
    return axios.delete(`${this.apiUrl}/${this.endpoint}`, {
      data, params: {
        application: this.application
      }, headers: authHeader()
    });
  }

  async treeList() {
    return axios.get(`${this.apiUrl}/${this.endpoint}/treeList`, {
      params: {
        application: this.application
      }, headers: authHeader()
    });
  }
}

export default new SectorService();