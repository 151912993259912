import UserServices from '../../../services/user.service';
import packagesService from "../../../services/packages.service";

import AddUser from './AddUser.vue';
import DeleteUser from './DeleteUser.vue';
import BulkDeleteUser from './BulkDeleteUser.vue';

export default {
	name: 'Users',
	components: {
		AddUser,
		DeleteUser,
		BulkDeleteUser,
	},
	data() {
		return {
			users: [],
			tableFields: [
				{ key: 'select', label: '' },
				{ key: 'fullName', label: 'Adı', _style: 'min-width:200px' },
				{ key: 'packageName', label: 'Paket', _style: 'min-width:200px' },
				{ key: 'email', label: 'E-posta', _style: 'min-width:200px' },
				{ key: 'active', label: 'Aktif', _style: 'min-width:200px' },
				{ key: 'actions', label: '', _style: 'width:1%', sorter: false, filter: false },
			],
			details: [],
			itemsPerPage: 10,
			collapseDuration: 0,
			userListVisible: true,
			tableFilter: {
				label: 'Filtrele',
				placeholder: 'Aranacak kelimeyi girin',
				external: true,
				lazy: true,
			},
			item: {},
			editing: false,
			noItemsViewSettings: {
				noResults: 'Filtrelenecek sonuç yok',
				noItems: 'Görüntülenecek bilgi yok',
			},
			itemsPerPageSettings: { label: 'Sayı', values: [10, 20, 50, 100] },
			activePage: 1,
			totalNumberOfPages: 1,
			emptyItem: {
				name: '',
				surname: '',
				email: '',
				role: '',
				active: true,
			},
			selectedItems: [],
			allItemsSelected: false,
			search: {
				name: '',
				surname: '',
				email: '',
			},
			tableFilterText: '',
			role: '',
			selectedPackage: null,
			packages: [],
		};
	},
	methods: {
		addItem() {
			this.$refs.addModal.show({
				loadItemsFunc: this.loadItems, item: { ...this.emptyItem }
			});
		},
		async editItem(item) {
			const existingItem = { ...this.emptyItem, ...item, password: '' };
			this.$refs.addModal.show({
				loadItemsFunc: this.loadItems, item: existingItem
			});
		},
		async deleteItem(item) {
			this.$refs.deleteModal.show({ loadItemsFunc: this.loadItems, item: { name: `${item.name} ${item.surname}`, _id: item._id } });
		},
		async bulkDeleteItems() {
			this.$refs.bulkDeleteModal.show({
				loadItemsFunc: this.loadItems,
				items: this.selectedItems,
			});
		},
		getSelectedItems() {
			this.selectedItems = this.users.filter((x) => {
				if (x._selected) return x._id;
			});
		},
		async paginationChage(e) {
			this.itemsPerPage = e;
			this.allItemsSelected = false;
			await this.loadItems();
		},
		getBadge(active) {
			return active ? "success" : "danger";
		},
		async loadItems(params) {
			try {
				this.selectedItems = [];
				const res = await UserServices.list({
					page: this.activePage,
					skip: (this.activePage - 1) * this.itemsPerPage,
					limit: this.itemsPerPage,
					...params,
				});

				this.users = res.data.results.map((x) => {
					return {
						...x,
						name: x.name,
						surname: x.surname,
						email: x.email,
						role: x.role,
						active: x.active,
						company: x.company,
						package: x.package,
						packageName: x.packageInfo ? x.packageInfo.name : '',
						fullName: `${x.name} ${x.surname}`,
					};
				});
				this.totalNumberOfPages = res.data.pageCount;
			} catch (err) {
				console.error(err);
				throw err;
			}
		},
		check(item) {
			const val = Boolean(item._selected);
			this.users = this.users.map(user => user._id === item._id ? ({ ...user, _selected: !val }) : user);
			this.getSelectedItems();
		},
		checkAll(checked) {
			this.users.forEach((item) => {
				this.$set(item, "_selected", checked);
			});
			this.getSelectedItems();
		},
		async changePackage(e) {
			const val = e.target.value;
			if (val !== 'Paket seçiniz') {
				await this.serverSearch(null, val);
			} else {
				await this.serverSearch();
			}
		},
		async serverSearch(e, selectedPackage) {
			this.activePage = 1;
			const active = ["", "true", "false"];
			await this.loadItems({
				name: this.search.name,
				active: active[this.search.active],
				package: selectedPackage
			})
		},
	},
	async mounted() {
		try {
			this.role = this.$store.state.auth.usr.role;
			this.isCustomer = this.$store.state.auth.usr.role === 'customer';
			await this.loadItems(this.$route.query);
			if (this.role === 'admin') {
				const res = await packagesService.get({});
				this.packages = res.data.results.map(x => ({
					value: x._id,
					label: x.name
				}));
				this.packages = ['Paket seçiniz', ...this.packages];
			}
		} catch (err) {
			console.error("err", err);
		}
	},
	watch: {
		tableFilterText: async function (n, o) {
			this.activePage = 1;
			await this.loadItems({ name: n });
		},
		selectedItems(n, o) {
			this.allItemsSelected =
				n.length > 0 && n.length === this.users.length;
		},
	},
};