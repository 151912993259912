import codeService from "../../../services/code.service";
import sectorService from "../../../services/sector.service";

export default {
	name: "AddCode",
	data() {
		return {
			modalVisible: false,
			typeVisible: true,
			item: {
				type: null,
				name: null
			},
			loadItemsFunc: Function,
			types: [{ value: 0, label: "Tip Seçiniz" },
			{ value: 1, label: "Faaliyet Alanı" },
			{ value: 2, label: "Firma Tipi" },
			{ value: 3, label: "Emlak Tipi" },
			{ value: 4, label: "Emlak Satış Tipi" },
			{ value: 5, label: "Ürün Kategori" },
			{ value: 6, label: "Anahtar Kelimeler" }],
			sectors: [],
		};
	},
	methods: {
		async findSectors(query) {
			this.isLoading = true;
			try {
				const res = await sectorService.get({
					page: 1,
					adminView: true,
					name: query
				});
				const sectors = res.data.results.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				this.sectors = [...sectors];
			} catch (err) {
				throw err;
			}
			this.isLoading = false;
		},
		async show({ loadItemsFunc, item }) {
			if (item.type === 6) {
				if (item.parentId?.label) {
					await this.findSectors(item.parentId.label);
				}
			}
			this.modalVisible = true;
			this.loadItemsFunc = loadItemsFunc;
			this.item = { ...item }
			console.log(item)
			this.typeVisible = (item.type === 0);
		},
		cancel() {
			this.item = {
				province: null,
				name: null
			};
			this.modalVisible = false;
			this.errors.clear();
		},
		async save() {
			const isValid = await this.$validator.validateAll();
			if (!isValid) {
				return;
			}
			try {
				if (this.item.parentId) {
					this.item.parentId = this.item.parentId.value;
				}
				if (!this.item._id) {
					await codeService.post(this.item)
				} else {
					await codeService.put(this.item._id, this.item);
				}
				await this.loadItemsFunc();
				this.modalVisible = false;
				this.$toast.success({
					title: 'Kod ekleme',
					message: 'Kod başarıyla eklendi'
				});
				this.errors.clear();
			} catch (err) {
				const message =
					(err.response && err.response.data) ||
					err.message ||
					err.toString() ||
					err.error;

				this.$toast.error({
					title: 'Kod ekleme',
					message: message.error || message
				});

				console.error('addCode/_delete error: ', err);
			}
		},
	},
	created() {
		this.$validator.extend(
			'secildi', {
			getMessage: field => `${field} seçiniz`,
			validate: (value) => {
				if (parseInt(value) === 0) return false;
				return true;
			}
		});
	}
}