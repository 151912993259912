export default (role) => {
  if (role === "admin") {
    return [
      {
        _name: "CSidebarNav",
        _children: [
          {
            _name: "CSidebarNavItem",
            name: "Kontrol Paneli",
            to: "/dashboard",
            icon: "cil-speedometer",
          },
          {
            _name: "CSidebarNavItem",
            name: "Binalar",
            to: "/buildings",
            icon: "cil-building",
          },
          {
            _name: "CSidebarNavItem",
            name: "Firmalar",
            to: "/companies",
            icon: "cil-factory",
          },
          {
            _name: "CSidebarNavItem",
            name: "Sektörler",
            to: "/sectors",
            icon: "cil-gem",
          },
          {
            _name: "CSidebarNavItem",
            name: "Emlak İlanları",
            to: "/real-estates",
            icon: "cil-home",
          },
          {
            _name: "CSidebarNavItem",
            name: "Kampanyalar",
            to: "/campaigns",
            icon: "cil-basket",
          },
          {
            _name: "CSidebarNavItem",
            name: "Reklamlar",
            to: "/advertorials",
            icon: "cil-qr-code",
          },
          {
            _name: "CSidebarNavItem",
            name: "Keşfet",
            to: "/explores",
            icon: "cil-globe-alt",
          },
          {
            _name: "CSidebarNavItem",
            name: "İlçeler",
            to: "/districts",
            icon: "cil-layers",
          },
          {
            _name: "CSidebarNavItem",
            name: "Uygulama",
            to: "/application",
            icon: "cil-terminal",
          },
          {
            _name: "CSidebarNavItem",
            name: "Kodlar",
            to: "/codes",
            icon: "cil-code",
          },
          {
            _name: "CSidebarNavItem",
            name: "Ürünler",
            to: "/products",
            icon: "cil-gift",
          },
          {
            _name: "CSidebarNavItem",
            name: "İş İlanları",
            to: "/worklists",
            icon: "cil-gift",
          },
          {
            _name: "CSidebarNavItem",
            name: "Paketler",
            to: "/packages",
            icon: "cil-puzzle",
          },
          {
            _name: "CSidebarNavItem",
            name: "Kullanıcılar",
            to: "/users",
            icon: "cil-user",
          }
        ],
      },
    ];
  } else if (role === 'customer') {
    return [
      {
      _name: "CSidebarNav",
        _children: [
          /*
          {
            _name: "CSidebarNavItem",
            name: "Kontrol Paneli",
            to: "/dashboard",
            icon: "cil-speedometer",
          },
          */
          {
            _name: "CSidebarNavItem",
            name: "Firmalar",
            to: "/customer-companies",
            icon: "cil-factory",
          },
          {
            _name: "CSidebarNavItem",
            name: "Ürünler",
            to: "/customer-products",
            icon: "cil-gift",
          },
          {
            _name: "CSidebarNavItem",
            name: "İş İlanları",
            to: "/customer-worklists",
            icon: "cil-gift",
          },
          {
            _name: "CSidebarNavItem",
            name: "Kullanıcılar",
            to: "/users",
            icon: "cil-user",
          }
        ],
      }
    ]
  } else if (role === 'subCustomer') {
    return [
      {
      _name: "CSidebarNav",
        _children: [
          /*
          {
            _name: "CSidebarNavItem",
            name: "Kontrol Paneli",
            to: "/dashboard",
            icon: "cil-speedometer",
          },
          */
          {
            _name: "CSidebarNavItem",
            name: "Firmalar",
            to: "/customer-companies",
            icon: "cil-factory",
          },
          {
            _name: "CSidebarNavItem",
            name: "Ürünler",
            to: "/customer-products",
            icon: "cil-gift",
          },
          {
            _name: "CSidebarNavItem",
            name: "İş İlanları",
            to: "/customer-worklists",
            icon: "cil-gift",
          }
        ],
      }
    ]
  }
};
