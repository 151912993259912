var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',{attrs:{"align":"right"}},[_c('CButton',{attrs:{"color":"primary"},on:{"click":_vm.addItem}},[_vm._v(" Ekle ")])],1),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.tableFields,"items-per-page":_vm.itemsPerPage,"hover":"","column-filter":"","tableFilter":_vm.tableFilter,"noItemsView":_vm.noItemsViewSettings,"itemsPerPageSelect":_vm.itemsPerPageSettings,"tableFilterValue":_vm.tableFilterText},on:{"pagination-change":_vm.paginationChage,"update:tableFilterValue":function($event){_vm.tableFilterText=$event},"update:table-filter-value":function($event){_vm.tableFilterText=$event}},scopedSlots:_vm._u([{key:"under-table",fn:function(){return [(_vm.items.length > 0)?_c('div',{staticStyle:{"padding-left":"33px"}},[_c('CButton',{attrs:{"color":"danger","disabled":_vm.selectedItems.length === 0},on:{"click":_vm.bulkDeleteItems}},[_vm._v(" Toplu Sil ")]),_c('CPagination',{attrs:{"activePage":_vm.activePage,"pages":_vm.totalNumberOfPages,"size":"md","align":"center"},on:{"update:activePage":[function($event){_vm.activePage=$event},_vm.loadItems],"update:active-page":function($event){_vm.activePage=$event}}})],1):_vm._e()]},proxy:true},{key:"provinceName-filter",fn:function(ref){
var item = ref.item;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search.provinceName),expression:"search.provinceName"}],staticClass:"form-control form-control-sm mt-2",attrs:{"type":"input"},domProps:{"value":(_vm.search.provinceName)},on:{"change":_vm.serverSearch,"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.search, "provinceName", $event.target.value)},_vm.serverSearch]}})]}},{key:"districtName-filter",fn:function(ref){
var item = ref.item;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search.districtName),expression:"search.districtName"}],staticClass:"form-control form-control-sm mt-2",attrs:{"type":"input"},domProps:{"value":(_vm.search.districtName)},on:{"change":_vm.serverSearch,"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.search, "districtName", $event.target.value)},_vm.serverSearch]}})]}},{key:"select-filter",fn:function(){return [_c('CInputCheckbox',{staticStyle:{"padding-left":"2rem"},attrs:{"checked":_vm.allItemsSelected,"custom":""},on:{"update:checked":[function($event){_vm.allItemsSelected=$event},function (e) { return _vm.checkAll(e); }]}})]},proxy:true},{key:"companyCount-filter",fn:function(){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search.companyCount),expression:"search.companyCount"}],staticClass:"form-control form-control-sm mt-2",attrs:{"type":"input"},domProps:{"value":(_vm.search.companyCount)},on:{"change":_vm.serverSearch,"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.search, "companyCount", $event.target.value)},_vm.serverSearch]}})]},proxy:true},{key:"realEstateCount-filter",fn:function(){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search.realEstateCount),expression:"search.realEstateCount"}],staticClass:"form-control form-control-sm mt-2",attrs:{"type":"input"},domProps:{"value":(_vm.search.realEstateCount)},on:{"change":_vm.serverSearch,"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.search, "realEstateCount", $event.target.value)},_vm.serverSearch]}})]},proxy:true},{key:"select",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CInputCheckbox',{attrs:{"checked":item._selected,"custom":""},on:{"update:checked":function () { return _vm.check(item); }}})],1)]}},{key:"districtName",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{attrs:{"href":("/#/companies?district=" + (item.districtId)),"target":"_blank"}},[_vm._v(_vm._s(item.districtName))])])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"size":"sm","color":"danger"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" Sil ")])],1),_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"size":"sm","color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" Düzenle ")])],1)]}}])})],1)],1),_c('AddDistrict',{ref:"addModal"}),_c('DeleteDistrict',{ref:"deleteModal"}),_c('BulkDeleteDistrict',{ref:"bulkDeleteModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }