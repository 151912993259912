import CampaignService from "../../services/campaign.service";
import CompanyService from "../../services/company.service";
import Multimedia from "../Multimedia/Multimedia.vue";
import { VueEditor } from "vue2-editor";

export default {
	name: "Campaigns",
	components: {
		Multimedia,
		VueEditor
	},
	data() {
		return {
			campaigns: [],
			tableFields: [
				{ key: "select", label: "" },
				{ key: "name", label: "Kampanya Adı", _style: "min-width:200px" },
				{ key: "companyName", label: "Şirket Adı", _style: "min-width:200px" },
				{ key: "active", label: "Aktif", _style: "min-width:200px" },
				{ key: "order", label: "Sıra No", _style: "min-width:200px" },
				{
					key: "actions",
					label: "",
					_style: "width:1%",
					sorter: false,
					filter: false,
				},
			],
			itemsPerPageSettings: { label: "Sayı", values: [10, 20, 50, 100] },
			details: [],
			itemsPerPage: 10,
			collapseDuration: 0,
			listing: true,
			tableFilter: {
				label: "Filtrele",
				placeholder: "Aranacak kelimeyi girin",
				external: true,
				lazy: true,
			},
			deleteModal: false,
			itemToBeAdd: {},
			itemToBeDelete: {},
			editing: false,
			toasts: [],
			noItemsViewSettings: {
				noResults: "Filtrelenecek sonuç yok",
				noItems: "Görüntülenecek bilgi yok",
			},
			activePage: 1,
			totalNumberOfPages: 1,
			newItem: true,
			addItemModal: false,
			emptyItem: {
				image: undefined,
				order: 0,
				url: "",
				active: true,
				detail: "",
				company: 0
			},
			multimediaUrl: "",
			companies: [],
			tableFilterText: "",
			selectedItems: [],
			search: {
				name: '',
				company: '',
				active: 0,
				order: ''
			},
			allItemsSelected: false,
			batchDeleteModal: false,
		};
	},
	props: {
		companyId: String,
	},
	computed: {},
	async mounted() {
		try {
			this.companies = await this.loadCompanies();
			await this.loadItems();
		} catch (err) {
			console.error("error:", err);
		}
	},

	methods: {
		async loadItems(params) {
			try {
				this.selectedItems = [];
				const res = await CampaignService.get({
					page: this.activePage,
					skip: (this.activePage - 1) * this.itemsPerPage,
					limit: this.itemsPerPage,
					company: this.companyId,
					...params
				});
				console.info(res.data.results);
				const campaigns = res.data.results.map((x) => ({
					...x,
					companyName: x.company ? x.company.name : '',
					companyId: x.company ? x.company._id : null
				}));
				this.campaigns = campaigns;
				this.totalNumberOfPages = res.data.pageCount;
			} catch (err) {
				console.error("err:", err);
			}
		},
		getBadge(active) {
			return active ? "success" : "danger";
		},
		showAddItem() {
			this.listing = false;
			this.newItem = true;
			this.editing = false;
			this.multimediaUrl = "";
			this.itemToBeAdd = { ...this.emptyItem };
			this.addItemModal = true;
		},
		editItem(item) {
			if (item.company) {
				item.company = { label: item.company.name, value: item.company._id };
			}
			this.itemToBeAdd = { ...this.emptyItem, ...item };
			this.multimediaUrl = (item.image) ? `${process.env.VUE_APP_API_URL}/multimedia/${item.image._id}` : undefined;
			this.newItem = false;
			this.listing = false;
			this.editing = true;
			this.addItemModal = true;
		},
		hideAddItem() {
			this.listing = true;
			this.editing = false;
			this.message = "";
			this.newItem = false;
			this.addItemModal = false;
			this.errors.clear();
		},
		async saveItem() {
			try {
				const isValid = await this.$validator.validateAll();
				if (!isValid) {
					console.error(this.$validator.errors.all());
					return;
				}
				if (!this.editing) {
					const item = { ...this.itemToBeAdd };
					if (item.company) {
						item.company = item.company.value;
					}
					await CampaignService.post({
						...item
					});
					await this.loadItems();
					this.toasts.push({
						color: "success",
						content: "Yeni kampanya eklendi",
					});
					this.listing = true;
					this.editing = false;
					this.newItem = false;
					this.addItemModal = false;
				} else {
					if (!this.itemToBeAdd.image) {
						throw new Error("Resim seçilmesi zorunludur");
					}
					const item = { ...this.itemToBeAdd };
					if (item.company) {
						item.company = item.company.value;
					}
					await CampaignService.put(item._id, {
						...item
					});
					await this.loadItems();
					this.toasts.push({
						color: "success",
						content: "Kampanya güncellendi",
					});
					this.listing = true;
					this.editing = false;
					this.newItem = false;
					this.addItemModal = false;
				}
			} catch (err) {
				this.message =
					(err.response && err.response.data) || err.message || err.toString();
				console.error(this.message);
				this.toasts.push({
					color: "danger",
					content: `Hata: ${this.message.error || this.message}`,
				});
			}
		},
		openMultimedia() {
			this.$refs.mmodal.show("campaignsMedia", this.itemToBeAdd._id);
		},
		selectMultimedia(image, whereIs) {
			if (whereIs === "campaignsMedia") {
				this.itemToBeAdd.image = image.id;
				this.multimediaUrl = `${process.env.VUE_APP_API_URL}/multimedia/${image.id}`;
				this.$forceUpdate();
			}
		},
		async paginationChage(e) {
			this.activePage = 1;
			this.itemsPerPage = e;
			this.allItemsSelected = false;
			await this.loadItems();
		},
		async loadCompanies() {
			try {
				const res = await CompanyService.get({
					adminView: true,
					page: 1,
				});
				const companies = res.data.results.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				return companies;
			} catch (error) {
				console.error("Campaigns/loadCompanies error: ", error);
				return [];
			}
		},
		async findCompany(query) {
			this.isLoading = true;
			try {
				const res = await CompanyService.get({
					page: 1,
					adminView: true,
					name: query
				});
				const companies = res.data.results.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				this.companies = [...companies];
			} catch (err) {
				throw err;
			}
			this.isLoading = false;
		},
		getCompanyName(item) {
			return item.companyName ? item.companyName : "";
		},
		check(item) {
			const val = Boolean(item._selected);
			this.campaigns = this.campaigns.map(campaign => campaign._id === item._id ? ({...campaign, _selected: !val}) : campaign);
			this.getSelectedItems();
		},
		checkAll(checked) {
			this.campaigns.forEach((item) => {
				this.$set(item, "_selected", checked);
			});
			this.getSelectedItems();
		},
		async serverSearch(e) {
			this.activePage = 1;
			const active = ["", "true", "false"];
			await this.loadItems({ name: this.search.name, active: active[this.search.active], order: this.search.order, companyName: this.search.companyName })
		},
		getSelectedItems() {
			this.selectedItems = this.campaigns.filter((x) => {
				if (x._selected) return x._id;
			});
		},
		showDeleteModal(item) {
			this.deleteModal = true;
			this.itemToBeDelete = item;
		},
		showBatchDeleteModal() {
			this.batchDeleteModal = true;
		},
		async batchDeleteItems() {
			try {
				await CampaignService.delete([...this.selectedItems]);
				await this.loadItems();
				this.batchDeleteModal = false;
				this.selectedItems = [];
				this.allItemsSelected = false;
				this.activePage = 1;
			} catch (err) {
				console.error(err);
			}
		},
		async deleteItem() {
			try {
				await CampaignService.delete([this.itemToBeDelete._id]);
				await this.loadItems();
			} catch (err) {
				console.error(err);
			}
			this.deleteModal = false;
		},
	},
	watch: {
		tableFilterText: async function (n, o) {
			this.activePage = 1;
			await this.loadItems({ name: n });
		},
		selectedItems(n, o) {
			this.allItemsSelected = (n.length > 0 && (n.length === this.campaigns.length));
		}
	}
};
