import RealEstateService from "../../../services/real-estate.service";
import DistrictService from "../../../services/district.service";
import CodeService from "../../../services/code.service";
import ProvinceService from "../../../services/province.service";
import Multimedia from "../../../components/Multimedia/Multimedia.vue";
import CompanyService from "../../../services/company.service";
import Gallery from "../../../components/Gallery/Gallery.vue";
import codeService from "../../../services/code.service";

import AddRealEstate from "./AddRealEstate.vue";
import DeleteRealEstate from "./DeleteRealEstate.vue";
import BulkDeleteRealEstate from "./BulkDeleteRealEstate.vue";

import { numberWithGroupSeperator } from "../../../lib/utils";

import { ObjectId } from "bson";

export default {
	name: "RealEstates",
	components: {
		Multimedia,
		Gallery,
		AddRealEstate,
		DeleteRealEstate,
		BulkDeleteRealEstate
	},
	data() {
		return {
			realEstates: [],
			tableFields: [
				{ key: "select", label: "" },
				{ key: "realEstateId", label: "Emlak Id", _style: "min-width:200px" },
				{ key: "name", label: "Adı", _style: "min-width:200px" },
				{ key: "active", label: "Aktif", _style: "min-width:200px" },
				{ key: "price", label: "Fiyat", _style: "min-width:200px" },
				{ key: "order", label: "Sıra No", _style: "min-width:200px" },
				{
					key: "actions",
					label: "",
					_style: "width:1%",
					sorter: false,
					filter: false,
				},
			],
			details: [],
			itemsPerPage: 10,
			collapseDuration: 0,
			tableFilter: {
				label: "Filtrele",
				placeholder: "Aranacak kelimeyi girin",
				external: true,
				lazy: true,
			},
			noItemsViewSettings: {
				noResults: "Filtrelenecek sonuç yok",
				noItems: "Görüntülenecek bilgi yok",
			},
			itemsPerPageSettings: { label: "Sayı", values: [10, 20, 50, 100] },
			activePage: 1,
			totalNumberOfPages: 1,
			emptyItem: {
				_id: null,
				name: null,
				order: 0,
				sales_type: null,
				type: null,
				price: null,
				active: true,
				district: null,
				detail: "",
				multimedia: [],
				company: 0,
				location: { lat: 38.423734, lng: 27.142826, address: "İzmir, Turkey" },
				new: true
			},
			selectedItems: [],
			allItemsSelected: false,
			search: {
				name: '',
				active: 0,
				order: '',
				realEstateId: ''
			},
			tableFilterText: '',
			multimedias: [],
			salesTypes: [],
			salesType: null,
		};
	},
	computed: {},
	async mounted() {
		try {
			await this.loadItems();
			await this.findSalesType();
		} catch (err) {
			console.error("error:", err);
		}
	},
	methods: {
		async selectSalesType(selectedOption) {
			await this.loadItems({
				salesType: selectedOption.value
			});
		},
		async removeSalesType() {
			await this.loadItems({});
		},
		async loadCodes(codeType, query) {
			try {
				const resCode = await codeService.get({ type: codeType, name: query });

				return resCode.data.map(x => ({
					value: x._id,
					label: x.name,
				}));
			} catch (error) {
				console.error("addRealEstate/loadCodes:", error);
				return [];
			}
		},
		async findSalesType(query) {
			this.salesTypes = await this.loadCodes(4, query);
		},
		async paginationChage(e) {
			this.itemsPerPage = e;
			this.allItemsSelected = false;
			await this.loadItems();
		},
		async loadItems(params) {
			this.selectedItems = [];
			try {
				const res = await RealEstateService.get({
					page: this.activePage,
					skip: (this.activePage - 1) * this.itemsPerPage,
					limit: this.itemsPerPage,
					...params
				});
				this.totalNumberOfPages = res.data.pageCount;
				const realEstates = res.data.results;
				this.realEstates = realEstates.map((x) => ({
					_id: x._id,
					name: x.name,
					districtName: x.district?.name,
					sales_type: {
						value: x.sales_type._id,
						label: x.sales_type.name
					},
					type: {
						value: x.type._id,
						label: x.type.name
					},
					price: x.price,
					active: x.active,
					district: {
						value: x.district._id,
						label: x.district.name
					},
					order: x.order,
					detail: x.detail,
					province: {
						value: x.province._id,
						label: x.province.name
					},
					multimedia: x.multimedia,
					// company: x.company ? x.company._id : null
					company: {
						value: x.company._id,
						label: x.company.name
					},
					realEstateId: x.realEstateId,
					location: {
						lat: x.location?.lat,
						lng: x.location?.lng,
						address: x.location?.address
					}
				}));
			} catch (err) {
				console.error("err:", err);
			}
		},
		getBadge(active) {
			return active ? "success" : "danger";
		},
		async saveItem() {
			try {
				const isValid = await this.$validator.validateAll();
				if (!isValid) {
					console.error(this.$validator.errors.all());
					return;
				}
				if (!this.editing) {
					const item = { ...this.itemToBeAdd };
					const res = await RealEstateService.post({
						...item,
						company: this.companyId,
					});
					await this.loadItems();
					this.toasts.push({
						color: "success",
						content: "Yeni emlak ilanı eklendi",
					});
					this.listing = true;
					this.editing = false;
					this.newItem = false;
					this.addItemModal = false;
				} else {
					const item = { ...this.itemToBeAdd };
					const res = await RealEstateService.put(item._id, item);
					await this.loadItems();
					this.toasts.push({
						color: "success",
						content: "Emlak ilanı güncellendi",
					});
					this.listing = true;
					this.editing = false;
					this.newItem = false;
					this.addItemModal = false;
				}
			} catch (err) {
				this.message =
					(err.response && err.response.data) ||
					err.message ||
					err.toString() ||
					err.error;
				this.toasts.push({
					color: "danger",
					content: `Hata oluştu: ${this.message.error || this.message}`,
				});
			}
		},
		check(item) {
			const val = Boolean(item._selected);
			this.realEstates = this.realEstates.map(re => re._id === item._id ? ({ ...re, _selected: !val }) : re);
			this.getSelectedItems();
		},
		checkAll(checked) {
			this.realEstates.forEach((item) => {
				this.$set(item, "_selected", checked);
			});
			this.getSelectedItems();
		},
		getSelectedItems() {
			this.selectedItems = this.realEstates.filter((x) => {
				if (x._selected) {
					return x._id;
				}
			});
		},
		async serverSearch(e) {
			this.activePage = 1;
			const active = ["", "true", "false"];
			await this.loadItems({ name: this.search.name, active: active[this.search.active], order: this.search.order, realEstateId: this.search.realEstateId })
		},
		addItem() {
			this.$refs.addModal.show({
				loadItemsFunc: this.loadItems, item: { ...this.emptyItem }
			});
		},
		bulkDeleteItems() {
			this.$refs.bulkDeleteModal.show({ loadItemsFunc: this.loadItems, items: this.selectedItems });
		},
		deleteItem(item) {
			this.$refs.deleteModal.show({ loadItemsFunc: this.loadItems, item: { name: item.name, _id: item._id, type: item.type } });
		},
		editItem(item) {
			const existingItem = { ...item };
			this.$refs.addModal.show({
				loadItemsFunc: this.loadItems, item: existingItem
			});
		},
		numberWithGroupSeperator
	},
	watch: {
		tableFilterText: async function (n, o) {
			this.activePage = 1;
			await this.loadItems({ name: n });
		},
		selectedItems(n, o) {
			this.allItemsSelected = (n.length > 0 && (n.length === this.realEstates.length));
		}
	}
};
