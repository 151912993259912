<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App"
};
</script>

<style lang="scss">
@import "assets/scss/style";
@import "vue-select/src/scss/vue-select.scss";
// @import "~vue-ionicons/ionicons.scss";
</style>
