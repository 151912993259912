import axios from "axios";
import authHeader from "./auth-header";
import CrudService from './crud.service';

class MultimediaService extends CrudService {
  constructor(){
    super('multimedia');
  }

  fileUpload(files, whereIsId, whereIs) {
    let formData = new FormData();
    for (const file of files) {
      formData.append(`multimedia`, file);
    }
    formData.append('application', this.application);
    formData.append('whereIsId', whereIsId);
    formData.append('whereIs', whereIs);
    return axios.post(`${this.apiUrl}/${this.endpoint}`, formData, {headers:{ ...authHeader() , 'Content-Type': 'multipart/form-data' } });
  }
}

export default new MultimediaService();