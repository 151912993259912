import districtService from "../../../services/district.service";

export default {
	name: "BulkDeleteDistrict",
	data() {
		return {
			modalVisible: false,
			items: [],
			loadItemsFunc: Function
		};
	},
	methods: {
		show({ loadItemsFunc, items }) {
			this.items = [...items];
			this.loadItemsFunc = loadItemsFunc;
			this.modalVisible = true;
		},
		cancel() {
			this.item = {
				province: null,
				name: null
			};
			this.modalVisible = false;
		},
		async _delete() {
			try {
				await districtService.delete([...this.items]);
				await this.loadItemsFunc();
				this.$toast.success({
					title: 'Toplu İlçe silme',
					message: 'İlçeler başarıyla silindi'
				});
				this.modalVisible = false;
			} catch (err) {
				console.error('BulkDeleteDistrict/_delete error: ', err);
			}
		},
	},
}