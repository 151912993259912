import DistrictService from "../../../services/district.service";
import AddDistrict from "./AddDistrict.vue";
import DeleteDistrict from "./DeleteDistrict.vue";
import BulkDeleteDistrict from "./BulkDeleteDistrict.vue";

export default {
	name: "Districts",
	data() {
		return {
			tableFields: [
				{ key: "select", label: "" },
				{ key: "provinceName", label: "İl Adı", _style: "min-width:200px" },
				{ key: "districtName", label: "İlçe Adı", _style: "min-width:200px" },
				{ key: "companyCount", label: "Firma Sayısı", _style: "min-width: 200px" },
				// { key: "realEstateCount", label: "Emlak İlanı Sayısı", _style: "min-width: 200px"},
				{
					key: "actions",
					label: "",
					_style: "width:1%",
					sorter: false,
					filter: false,
				},
			],
			items: [],
			itemsPerPage: 10,
			tableFilter: {
				label: "Filtrele",
				placeholder: "Aranacak kelimeyi girin",
			},
			noItemsViewSettings: {
				noResults: "Filtrelenecek sonuç yok",
				noItems: "Görüntülenecek bilgi yok",
			},
			itemsPerPageSettings: { label: "Sayı", values: [10, 20, 50, 100] },
			activePage: 1,
			totalNumberOfPages: 1,
			selectedItems: [],
			allItemsSelected: false,
			search: {
				districtName: null,
				provinceName: null,
				realEstateCount: null,
				companyCount: null
			},
			tableFilterText: '',
		};
	},
	async mounted() {
		try {
			await this.loadItems();
		} catch (err) {
			console.error("error:", err);
		}
	},
	methods: {
		async paginationChage(e) {
			this.itemsPerPage = e;
			await this.loadItems();
		},
		async loadItems(params) {
			try {
				this.selectedItems = [];
				const res = await DistrictService.get({
					page: this.activePage,
					skip: (this.activePage - 1) * this.itemsPerPage,
					limit: this.itemsPerPage,
					...params
				});
				this.totalNumberOfPages = res.data.pageCount;
				const results = res.data.results;
				this.items = results.map((x) => ({
					districtId: x._id,
					districtName: x.name,
					provinceName: x.province?.name,
					provinceId: x.province?._id,
					realEstateCount: x.realEstateCount,
					companyCount: x.companyCount
				}));
			} catch (err) {
				console.error("err:", err);
			}
		},
		async deleteItem(item) {
			this.$refs.deleteModal.show({ loadItemsFunc: this.loadItems, item: { name: item.districtName, _id: item.districtId } });
		},
		async editItem(item) {
			const existingItem = {
				_id: item.districtId,
				province: item.provinceId,
				name: item.districtName
			};
			this.$refs.addModal.show({
				loadItemsFunc: this.loadItems, item: existingItem
			});
		},
		check(item) {
			const val = Boolean(item._selected);
			this.items = this.items.map(itm => itm.districtId === item.districtId ? ({ ...itm, _selected: !val }) : itm);
			this.getSelectedItems();
		},
		checkAll(checked) {
			this.items.forEach((item) => {
				this.$set(item, "_selected", checked);
			});
			this.getSelectedItems();
		},
		getSelectedItems() {
			this.selectedItems = [];
			this.items.forEach((x) => {
				if (x._selected) {
					this.selectedItems.push(x.districtId);
				}
			});
		},
		async serverSearch(e) {
			this.activePage = 1;
			await this.loadItems({ name: this.search.districtName, provinceName: this.search.provinceName, realEstateCount: this.search.realEstateCount, companyCount: this.search.companyCount })
		},
		addItem() {
			this.$refs.addModal.show({
				loadItemsFunc: this.loadItems, item: {
					_id: null,
					province: null,
					name: null
				}
			});
		},
		bulkDeleteItems() {
			this.$refs.bulkDeleteModal.show({ loadItemsFunc: this.loadItems, items: this.selectedItems });
		}
	},
	watch: {
		selectedItems(n, o) {
			this.allItemsSelected = (n.length > 0 && (n.length === this.items.length));
		}
	},
	components: {
		AddDistrict,
		DeleteDistrict,
		BulkDeleteDistrict
	}
}