import userService from "../../../services/user.service";

export default {
	name: "BulkDeleteUser",
	data() {
		return {
			modalVisible: false,
			items: [],
			loadItemsFunc: Function
		};
	},
	methods: {
		show({ loadItemsFunc, items }) {
			this.items = [...items];
			this.loadItemsFunc = loadItemsFunc;
			this.modalVisible = true;
		},
		cancel() {
			this.item = {
				province: null,
				name: null
			};
			this.modalVisible = false;
		},
		async _delete() {
			try {
				await userService.deleteSubCustomer(this.items.map(x => x._id));
				await this.loadItemsFunc();
				this.modalVisible = false;
				this.$toast.success({
					title: 'Toplu Kullanıcı silme',
					message: 'Kullanıcılar başarıyla silindi'
				});
			} catch (err) {
				const message =
					(err.response && err.response.data) ||
					err.message ||
					err.toString() ||
					err.error;

				this.$toast.error({
					title: 'Toplu Kullınıcı silme',
					message: message.error || message
				});
				console.error('bulkDeleteUser/_delete error: ', err);
			}
		},
	},
}