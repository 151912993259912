import CompanyService from ".../../../services/company.service";

import DeleteCompany from "./DeleteCompany.vue";
import BulkDeleteCompany from "./BulkDeleteCompany.vue";
import AddCompany from "./AddCompany.vue";
import CustomerCompany from "./CustomerCompany.vue";

export default {
  name: "Companies",
  data() {
    return {
      companies: [],
      tableFields: [
        { key: "select", label: "" },
        { key: "companyId", label: "Firma Id", _style: "min-width:50px" },
        { key: "name", label: "Şirket Adı", _style: "min-width:200px" },
        { key: "active", label: "Aktif", _style: "min-width:50px" },
        { key: "order", label: "Numarası", _style: "min-width:50px" },
        { key: "buildingName", label: "Bina", _style: "min-width:100px" },
        { key: "sectorName", label: "Sektör", _style: "min-width:100px" },
        { key: "companyScopes", label: "Faaliyet Alanı", _style: "min-width:100px" },
        { key: "keywordsNames", label: "Anahtar Kelimeler", _style: "min-width:100px" },
        {
          key: "actions",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
      ],
      details: [],
      itemsPerPage: 10,
      collapseDuration: 0,
      companyListVisible: true,
      tableFilter: {
        label: "Filtrele",
        placeholder: "Aranacak kelimeyi girin",
        external: true,
        lazy: true,
      },
      item: {},
      editing: false,
      noItemsViewSettings: {
        noResults: "Filtrelenecek sonuç yok",
        noItems: "Görüntülenecek bilgi yok",
      },
      itemsPerPageSettings: { label: "Sayı", values: [10, 20, 50, 100] },
      activePage: 1,
      totalNumberOfPages: 1,
      emptyItem: {
        name: "",
        order: "",
        active: true,
        multimedia: [],
        description: "",
        telephone: "",
        view_telephone: false,
        email: "",
        view_email: false,
        opening_hour: "09:00",
        closing_hour: "18:30",
        website: "",
        websiteDisplayName: "",
        company_scopes: [],
        detail_description: "",
        type: "",
        district: null,
        building: null,
        top_view: false,
        top_view_start_date: "",
        top_view_period: "",
        sector: null,
        address: "",
        location: { lat: 38.423734, lng: 27.142826, address: "İzmir, Turkey" },
        special_view: false,
        special_view_period: "",
        special_view_start_date: null,
        socialNetworks: [],
        sliderType: "photo",
        whatsappNumber: "",
        whatsappNumberVisible: false,
        admin: null,
        owner: null,
        taxNo: null,
        taxAdmin: null
      },
      selectedItems: [],
      allItemsSelected: false,
      search: {
        name: "",
        active: 0,
        order: "",
        companyId: "",
        buildingName: "",
        sectorName: "",
        companyScope: ""
      },
      tableFilterText: "",
      hasBuildingParam: false,
      hasSectorParam: false,
      role: "",
    };
  },
  components: {
    DeleteCompany,
    BulkDeleteCompany,
    AddCompany,
    CustomerCompany,
  },
  methods: {
    hideAddCompanyCard() {
      this.companyListVisible = true;
    },
    showAddCompanyCard() {
      this.companyListVisible = false;
    },
    async editItem(item) {
      this.item = { ...this.emptyItem, ...item };
      this.showAddCompanyCard();
    },
    async updateActive(item, active) {
      try {
        await CompanyService.updateActive(item._id, active);
        item.active = active;
      } catch (err) {
        console.error(err);
      }
    },
    getSelectedItems() {
      this.selectedItems = this.companies.filter((x) => {
        if (x._selected) return x._id;
      });
    },
    async approve(item) {
      try {
        await CompanyService.approve(item._id);
      } catch (err) {
        this.$toast.error({
          title: 'Firma Onaylama',
          message: err.response.data.error
        });
        return;  
      }
      await this.loadItems();
      this.$toast.success({
        title: 'Firma Onaylama',
        message: 'Başarılı'
      });
    },
    async paginationChage(e) {
      this.itemsPerPage = e;
      this.allItemsSelected = false;
      await this.loadItems();
    },
    async loadItems(params) {
      try {
        this.selectedItems = [];
        const res = await CompanyService.get({
          page: this.activePage,
          skip: (this.activePage - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
          adminView: true,
          ...params,
        });
        this.companies = res.data.results.map((x) => {
          return {
            ...x,
            buildingName: x?.building?.name || "",
            sectorName: x.sector.map((x) => x.name).join(),
            companyScopes: x.company_scopes.map(itm => itm.name).join(),
            keywordsNames: x.keywords.map(itm => itm.name).join()
          };
        });
        //this.companies = res.data.results;
        this.totalNumberOfPages = res.data.pageCount;
        if (this.hasBuildingParam && res.data.results.length > 0) {
          this.search.buildingName = res.data.results[0].building.name;
        }
        if (this.hasSectorParam && res.data.results.length > 0) {
          this.search.sectorName = res.data.results[0].sector.map(x => x.name).join();
        }
        this.hasBuildingParam = false;
        this.hasSectorParam = false;
      } catch (err) {
        throw err;
      }
    },
    getBadge(active) {
      return active ? "success" : "danger";
    },
    check(item) {
      const val = Boolean(item._selected);
      this.companies = this.companies.map((cmp) =>
        cmp._id === item._id ? { ...cmp, _selected: !val } : cmp
      );
      this.getSelectedItems();
    },
    checkAll(checked) {
      this.companies.forEach((item) => {
        this.$set(item, "_selected", checked);
      });
      this.getSelectedItems();
    },
    async serverSearch(e) {
      this.activePage = 1;
      const active = ["", "true", "false"];
      await this.loadItems({
        name: this.search.name,
        active: active[this.search.active],
        order: this.search.order,
        companyId: this.search.companyId,
        buildingName: this.search.buildingName,
        sectorName: this.search.sectorName,
        companyScopes: this.search.companyScopes,
      });
    },
    async deleteItem(item) {
      this.$refs.deleteModal.show({
        loadItemsFunc: this.loadItems,
        item: { name: item.name, _id: item._id, type: item.type },
      });
    },
    bulkDeleteItems() {
      this.$refs.bulkDeleteModal.show({
        loadItemsFunc: this.loadItems,
        items: this.selectedItems,
      });
    },
    addItem() {
      this.item = { ...this.emptyItem };
      this.showAddCompanyCard();
    },
  },
  async mounted() {
    try {
      this.role = this.$store.state.auth.usr.role;
      if (this.$route.query.building) {
        this.hasBuildingParam = true;
      }
      if (this.$route.query.sector) {
        this.hasSectorParam = true;
      }
      await this.loadItems(this.$route.query);
    } catch (err) {
      console.error("err", err);
    }
  },
  watch: {
    tableFilterText: async function (n, o) {
      this.activePage = 1;
      await this.loadItems({ name: n });
    },
    selectedItems(n, o) {
      this.allItemsSelected =
        n.length > 0 && n.length === this.companies.length;
    },
  },
};
