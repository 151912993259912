import realEstateService from "../../../services/real-estate.service";
import provinceService from "../../../services/province.service";
import districtService from "../../../services/district.service";
import codeService from "../../../services/code.service";
import AddCode from "../Codes/AddCode.vue";
import { VueEditor } from "vue2-editor";

import Multimedia from "../../../components/Multimedia/Multimedia.vue";
import companyService from "../../../services/company.service";
import Gallery from "../../../components/Gallery/Gallery.vue";
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput.vue';
import GoogleMap from "../../../components/GoogleMap/GoogleMap.vue";

export default {
	name: "AddRealEstate",
	components: {
		Multimedia,
		Gallery,
		AddCode,
		CurrencyInput,
		GoogleMap,
		VueEditor
	},
	data() {
		return {
			modalVisible: false,
			isLoading: false,
			item: {
				location: {
					lat: 0,
					lng: 0,
					address: ''
				}
			},
			loadItemsFunc: Function,
			types: [],
			districts: [],
			provinces: [],
			salesTypes: [],
			companies: [],
			buildings: [],
		};
	},
	async mounted() {
		try {
			await this.findCompany();
			await this.findProvince();
			this.types = await this.loadCodes(3);
			this.salesTypes = await this.loadCodes(4);
		} catch (err) {
			console.error("addWorklist/mounted error:", err)
		}
	},
	methods: {
		selectPosition(lat, lng) {
			this.item.location.lat = lat;
			this.item.location.lng = lng;
		},
		async show({ loadItemsFunc, item }) {
			this.modalVisible = true;
			this.loadItemsFunc = loadItemsFunc;
			this.item = { ...item };
			await this.findCompany(this.item.company.label);
			this.$forceUpdate();
		},
		cancel() {
			this.modalVisible = false;
			this.errors.clear();
		},
		async save() {
			const isValid = await this.$validator.validateAll();
			if (!isValid) {
				return;
			}
			const item = { ...this.item };
			item.company = item.company.value;
			item.province = item.province.value;
			item.district = item.district.value;
			item.type = item.type.value;
			item.sales_type = item.sales_type.value;
			item.price = item.price ? item.price : 0
			try {
				if (item.new) {
					delete item.new;
					await realEstateService.post(item);
					this.$toast.success({
						title: 'Emlak İlanı ekleme',
						message: 'Başarıyla eklendi'
					});
				} else {
					await realEstateService.put(item._id, item);
					this.$toast.success({
						title: 'Emlak İlanı düzenleme',
						message: 'Başarıyla düzenlendi'
					});
				}
				await this.loadItemsFunc();
				this.modalVisible = false;
			} catch (err) {
				const message =
					(err.response && err.response.data) ||
					err.message ||
					err.toString() ||
					err.error;

				this.$toast.error({
					title: 'Emlak İlanı ekleme',
					message: message.error || message
				});

				console.error('addRealEstate/save error: ', err);
			}
		},
		async findCompany(query) {
			this.isLoading = true;
			try {
				const res = await companyService.get({
					page: 1,
					adminView: true,
					name: query
				});
				const companies = res.data.results.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				this.companies = [...companies];
			} catch (err) {
				throw err;
			}
			this.isLoading = false;
		},
		async findProvince(query) {
			try {
				const resProvince = await provinceService.get({
					name: query
				});
				const provinces = resProvince.data.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				this.provinces = [...provinces];
			} catch (err) {
				console.error("addRealEsate/findProvince error: ", err);
				return [];
			}
		},
		async provinceChange(event) {
			const res = await districtService.get({
				province: event.value
			});
			const districts = res.data.map((x) => ({
				value: x._id,
				label: x.name,
			}));
			this.districts = [...districts];
			this.$forceUpdate();
		},
		async findDistrict(query) {
			try {
				const res = await districtService.get({
					province: this.item.province.value,
					name: query
				});
				const districts = res.data.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				this.districts = [...districts];
				this.$forceUpdate();
			} catch (err) {
				throw err;
			}
		},
		async findSalesType(query) {
			this.salesTypes = await this.loadCodes(4, query);
		},
		async findType(query) {
			this.types = await this.loadCodes(3, query);
		},
		async loadCodes(codeType, query) {
			try {
				const resCode = await codeService.get({ type: codeType, name: query });

				return resCode.data.map(x => ({
					value: x._id,
					label: x.name,
				}));
			} catch (error) {
				console.error("addRealEstate/loadCodes:", error);
				return [];
			}
		},
		addSalesType() {
			this.$refs.addModal.show({
				loadItemsFunc: this.findSalesType, item: {
					_id: null,
					type: 4,
					name: null
				}
			});
		},
		addType() {
			this.$refs.addModal.show({
				loadItemsFunc: this.findType, item: {
					_id: null,
					type: 3,
					name: null
				}
			});
		},
		openMultimedia() {
			this.$refs.mmodal.show("realEstate", this.item._id);
		},
		selectMultimedia(image, whereIs) {
			if (whereIs === "realEstate") {
				if (this.item.multimedia.filter(itm => itm === image.id).length > 0) {
					alert('Bu resim zaten listeye eklenmiş.');
				} else {
					this.item.multimedia.push(image.id);
				}
			}
		},
		changedMultimedia(whereIs, items) {
			if (whereIs === 'realEstate') {
				this.item.multimedia = items;
			}
		},
	},
	created() {
		this.$validator.extend(
			'secildi', {
			getMessage: field => `${field} seçiniz`,
			validate: (value) => {
				if (!value.value) return false;
				return true;
			}
		});
	},
}