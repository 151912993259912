import axios from "axios";
import authHeader from "./auth-header";


class CrudService {
  apiUrl = process.env.VUE_APP_API_URL;
  application = localStorage.getItem('application');
  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  async get(params) {
    return axios.get(`${this.apiUrl}/${this.endpoint}`, {
      params: {
        ...params,
        application: this.application,
        adminView: true
      }, headers: authHeader()
    });
  }

  async post(data, params) {
    return axios.post(`${this.apiUrl}/${this.endpoint}`, { ...data, application: this.application }, {
      params
      , headers: authHeader()
    });
  }

  async put(id, data, params) {
    return axios.put(`${this.apiUrl}/${this.endpoint}/${id}`, data, {
      params: {
        ...params,
        application: this.application
      }, headers: authHeader()
    });
  }

  /*
  async delete(id) {
    return axios.delete(`${this.apiUrl}/${this.endpoint}/${id}`, { headers: authHeader() });
  }
  */
  delete(data) {
    return axios.delete(`${this.apiUrl}/${this.endpoint}`, {
      data, params: {
        application: this.application
      }, headers: authHeader()
    });
  }
}

export default CrudService;