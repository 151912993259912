import CrudService from './crud.service';
import axios from "axios";
import authHeader from "./auth-header";
class CompanyService extends CrudService {
  constructor() {
    super('company');
  }

  delete(data) {
    return axios.delete(`${this.apiUrl}/${this.endpoint}`, {
      data, params: {
        application: this.application
      }, headers: authHeader()
    });
  }

  customerCompany() {
    return axios.get(`${this.apiUrl}/${this.endpoint}/customer`, {
      params: {
        application: this.application
      }, headers: authHeader()
    })
  }

  updateActive(id, active) {
    return axios.put(`${this.apiUrl}/${this.endpoint}/updateActive/${id}`, { active: active }, {
      params: {
        application: this.application
      }, headers: authHeader()
    });
  }

  approve(id) {
    return axios.put(`${this.apiUrl}/${this.endpoint}/${id}/approve`, {
      id
    }, {
      params: {
        application: this.application
      }, headers: authHeader()
    });
  }
}

export default new CompanyService();