import productsService from "../../../services/products.service";
import sectorService from "../../../services/sector.service";
import categoryService from "../../../services/category.service";
import companyService from "../../../services/company.service";
import codeService from "../../../services/code.service";

import Multimedia from "../../../components/Multimedia/Multimedia.vue";
import Gallery from "../../../components/Gallery/Gallery.vue";
import AddCode from "../Codes/AddCode.vue";

import { VueEditor } from "vue2-editor";
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput.vue'

export default {
	name: "AddProduct",
	components: {
		Multimedia,
		Gallery,
		VueEditor,
		AddCode,
		CurrencyInput
	},
	data() {
		return {
			isCustomer: false,
			isLoading: false,
			modalVisible: false,
			item: {},
			provinces: [],
			loadItemsFunc: Function,
			sectors: [],
			categories: [],
			companies: [],
		};
	},
	async mounted() {
		try {
			this.isCustomer = this.$store.state.auth.usr.role === 'customer';
			await this.loadCompanies();
			this.categories = await this.loadCodes(5);
		} catch (err) {
			console.error("addProduct/mounted error:", err)
		}
	},

	methods: {
		async show({ loadItemsFunc, item }) {
			this.item = { ...item }
			if (item._id) {
				this.item.company = { label: this.item.company.name, value: this.item.company._id };
				await this.findCompany(this.item.company.label);
				this.item.category = this.item.category.map(x => ({ value: x._id, label: x.name }));
				await this.findCategory(this.item.category.map(x => x.label));
			} else {
				this.item.multimedia = [];
			}
			this.modalVisible = true;
			this.loadItemsFunc = loadItemsFunc;
		},
		cancel() {
			this.item = {

			};
			this.modalVisible = false;
		},
		async save() {
			try {
				const isValid = await this.$validator.validateAll();
				if (!isValid) {
					console.error(this.$validator.errors.all());
					return;
				}
				const item = { ...this.item };
				item.category = this.item.category.map((x) => x.value);
				item.company = item.company.value;
				item.price = !item.price ? 0 : item.price;
				item.oldPrice = !item.oldPrice ? 0 : item.oldPrice;
				if (!this.item._id) {
					const res = await productsService.post({
						...item
					});
					await this.loadItemsFunc();
					this.$toast.success({
						title: 'Ürün Ekleme',
						message: 'Yeni ürün başarıyla eklendi'
					});
				} else {
					const res = await productsService.put(item._id, item);
					await this.loadItemsFunc();
					this.$toast.success({
						title: 'Ürün Düzenleme',
						message: 'Yeni ürün başarıyla düzenlendi'
					});
				}
				this.modalVisible = false;
			} catch (err) {
				const message =
					(err.response && err.response.data) ||
					err.message ||
					err.toString() ||
					err.error;

				this.$toast.error({
					title: 'Ürün işlemleri',
					message: message.error || message
				});
				console.error('addProduct/save error: ', err);
			}
		},
		openMultimedia() {
			this.$refs.mmodal.show('productMultimedia', this.item._id);
		},
		selectMultimedia(image, whereIs) {
			if (whereIs === 'productMultimedia') {
				if (this.item.multimedia.filter(itm => itm === image.id).length > 0) {
					alert('Bu resim zaten listeye eklenmiş.');
				} else {
					this.item.multimedia.push(image.id);
				}
			}
		},
		changedMultimedia(whereIs, items) {
			if (whereIs === 'productMultimedia') {
				this.item.multimedia = items;
			}
		},
		async loadCategories() {
			try {
				const res = await categoryService.get({
					page: 1,
					skip: 0,
					limit: 10,
				});
				const categories = res.data.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				this.categories = [...categories];
			} catch (err) {
				throw err;
			}
		},
		async loadCompanies() {
			try {
				const res = await companyService.get({
					adminView: true,
					page: 1,
				});
				const companies = res.data.results.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				this.companies = companies;
			} catch (error) {
				console.error("Explores/loadCompanies error: ", error);
				return [];
			}
		},
		showAddCategory() {
			this.$refs.addCategoryModal.show({ refreshCategories: this.loadCategories })
		},
		async findCompany(query) {
			this.isLoading = true;
			try {
				const res = await companyService.get({
					page: 1,
					adminView: true,
					name: query
				});
				const companies = res.data.results.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				this.companies = [...companies];
			} catch (err) {
				throw err;
			}
			this.isLoading = false;
		},
		async loadCodes(codeType, query) {
			try {
				const resCode = await codeService.get({ type: codeType, name: query });

				return resCode.data.map(x => ({
					value: x._id,
					label: x.name,
				}));
			} catch (error) {
				console.error("addRealEstate/loadCodes:", error);
				return [];
			}
		},
		async findCategory(query) {
			this.categories = await this.loadCodes(5, query);
		},
		addCategory() {
			this.$refs.addCode.show({
				loadItemsFunc: this.findCategory, item: {
					_id: null,
					type: 5,
					name: null
				}
			});
		},
	},
	created() {
		this.$validator.extend(
			'secildi', {
			getMessage: field => `${field} seçiniz`,
			validate: (value) => {
				if (parseInt(value) === 0) return false;
				return true;
			}
		});
	}
}