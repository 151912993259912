import moment from "moment";
import { VueEditor } from "vue2-editor";

import companyService from "../../../services/company.service";
import buildingService from "../../../services/building.service";
import districtService from "../../../services/district.service";
import sectorService from "..../../../services/sector.service";
import provinceService from "../../../services/province.service";
import codeService from "../../../services/code.service";
import userService from "../../../services/user.service";

import GoogleMap from "../../../components/GoogleMap/GoogleMap.vue";
import SocialNetworks from "../../../components/SocialNetworks/SocialNetworks.vue";
import Multimedia from "../../../components/Multimedia/Multimedia.vue";
import Gallery from "../../../components/Gallery/Gallery.vue";
import AddCode from "../Codes/AddCode.vue";

export default {
  name: "CustomerCompany",
  components: {
    Multimedia,
    GoogleMap,
    VueEditor,
    SocialNetworks,
    Gallery,
    AddCode,
  },
  props: { item: Object, hideCard: Function, refreshList: Function },
  data() {
    return {
      isCustomer: true,
      isLoading: false,
      periods: [
        { value: null, label: "periyot seçiniz" },
        { value: 30, label: 30 },
        { value: 60, label: 60 },
        { value: 90, label: 90 },
      ],
      companyTypes: [],
      buildings: [],
      districts: [],
      companyScopes: [],
      companyMultimedia: [],
      categories: [],
      sectors: [],
      multimediaUrl: "",
      companyLogoUrl: "",
      provinces: [],
      sliderTypes: [
        { value: "photo", label: "Resim" },
        { value: "video", label: "Video" },
      ],
      subUsers: [],
      isAdminOrCustomer: false,
      keywords: []
    };
  },
  methods: {
    async selectSector(selectedOption) {
      await this.findKeywords(null, selectedOption.value);
    },

    async removeSector() {
      await this.findKeywords();
    },

    selectPosition(lat, lng) {
      this.item.location.lat = lat;
      this.item.location.lng = lng;
    },
    cancel() {
      this.item = {};
      this.modalVisible = false;
    },
    async save() {
      try {
        console.log(this.item);
        const isValid = await this.$validator.validateAll();
        if (!isValid) {
          console.error(this.$validator.errors.all());
          window.scrollTo(0, 0);
          return;
        }
        const item = { ...this.item };
        item.special_view_start_date = moment(
          item.special_view_start_date,
          "DD/MM/YYYY"
        );
        item.top_view_start_date = moment(
          item.top_view_start_date,
          "DD/MM/YYYY"
        );
        item.company_scopes = this.item.company_scopes.map((x) => x.value);
        item.type = this.item.type.value;
        item.sector = this.item.sector.value;
        item.keywords = this.item.keywords.map(
          (x) => x.value
        );
        item.district = this.item.district.value;
        item.province = this.item.province.value;
        item.building = this.item.building.value;
        if (!this.item._id) {
          const res = await companyService.post(item);
          this.item._id = res.data._id;
          await this.refreshList();
          this.$toast.success({
            title: "Firma ekleme",
            message: "Başarılı",
          });
          this.hideCard();
        } else {
          const res = await companyService.put(item._id, item);
          await this.refreshList();
          this.$toast.success({
            title: "Firma düzenleme",
            message: "Başarılı",
          });
          this.hideCard();
        }
      } catch (err) {
        const message =
          (err.response && err.response.data) || err.message || err.toString();
        console.error(this.message);
        this.$toast.error({
          title: "Firma işlemleri",
          message: message.error || message,
        });
      }
    },
    async findSectors(query) {
      this.isLoading = true;
      try {
        const res = await sectorService.get({
          page: 1,
          adminView: true,
          name: query,
        });
        const sectors = res.data.results.map((x) => ({
          value: x._id,
          label: x.name,
        }));
        this.sectors = [...sectors];
      } catch (err) {
        throw err;
      }
      this.isLoading = false;
    },
    async findCompanyScopes(query) {
      this.isLoading = true;
      try {
        this.companyScopes = await this.loadCodes(1, query);
      } catch (err) {
        console.error("findCompanyScopes", err);
      }
      this.isLoading = false;
    },
    async findCompanyTypes(query) {
      this.isLoading = true;
      try {
        this.companyTypes = await this.loadCodes(2, query);
      } catch (err) {
        console.error("findCompanyScopes", err);
      }
      this.isLoading = false;
    },
    openMultimediaForCompanyLogo() {
      this.$refs.mmodal.show("firmaLogo", this.item._id);
    },
    selectMultimedia(image, whereIs) {
      if (whereIs === "firmaLogo") {
        this.item.logo = image.id;
        this.companyLogoUrl = `${process.env.VUE_APP_API_URL}/multimedia/${image.id}`;
      }
      if (whereIs === "firmaMultimedia") {
        if (this.item.multimedia.filter((itm) => itm === image.id).length > 0) {
          alert("Bu resim zaten listeye eklenmiş.");
        } else {
          this.item.multimedia.push(image.id);
        }
      }
    },
    changedMultimedia(whereIs, items) {
      if (whereIs === "firmaMultimedia") {
        this.item.multimedia = items;
      }
    },
    openMultimedia() {
      this.$refs.mmodal.show("firmaMultimedia", this.item._id);
    },
    async findBuilding(query) {
      try {
        let res = await buildingService.get({
          name: query,
          page: 1,
          all: true,
        });
        const buildings = res.data.results.map((x) => ({
          value: x._id,
          label: x.name,
        }));
        this.buildings = [...buildings];
      } catch (err) {
        throw err;
      }
    },
    async provinceChange(event) {
      this.item.district = null;
      const res = await districtService.get({
        province: event.value,
      });
      const districts = res.data.map((x) => ({
        value: x._id,
        label: x.name,
      }));
      this.districts = [...districts];
      this.$forceUpdate();
    },
    async loadCodes(codeType, name) {
      try {
        const resCode = await codeService.get({ type: codeType, name: name });
        return resCode.data.map((x) => ({
          value: x._id,
          label: x.name,
        }));
      } catch (error) {
        console.error("Companies/loadCodes:", error);
        return [];
      }
    },
    async findSubUsers(query) {
      this.isLoading = true;
      try {
        const res = await userService.list({
          page: 1,
          adminView: true,
          parentUser: query
        });
        const users = res.data.results.map((x) => ({
          value: x._id,
          label: x.name,
        }));
        this.subUsers = [...users];
      } catch (err) {
        throw err;
      }
      this.isLoading = false;
    },
    async findDistrict(query) {
      try {
        if (this.item.province?.value) {
          const res = await districtService.get({
            province: this.item.province.value,
            name: query,
          });
          const districts = res.data.map((x) => ({
            value: x._id,
            label: x.name,
          }));
          this.districts = [...districts];
          this.$forceUpdate();
        }
      } catch (err) {
        throw err;
      }
    },
    async findProvince(query) {
      try {
        const resProvince = await provinceService.get({
          name: query,
        });
        const provinces = resProvince.data.map((x) => ({
          value: x._id,
          label: x.name,
        }));
        this.provinces = [...provinces];
      } catch (err) {
        console.error("addCompany/findProvince error: ", err);
        return [];
      }
    },
    addCompanyScope() {
      console.log(this.$refs);
      this.$refs.addCodeModal.show({
        loadItemsFunc: this.findCompanyScopes,
        item: {
          _id: null,
          type: 1,
          name: null,
        },
      });
    },
    addCompanyType() {
      console.log("addcompany");
      this.$refs.addCodeModal.show({
        loadItemsFunc: this.findCompanyTypes,
        item: {
          _id: null,
          type: 2,
          name: null,
        },
      });
    },
    async loadItem() {
      try {
        const { data: res } = await companyService.customerCompany();
        this.item = {
          ...this.emptyItem,
          ...res,
          buildingName: res.building.name,
          sectorName: res.sector.map((x) => x.name).join(),
          companyScopes: res.company_scopes.map((itm) => itm.name).join(),
        };
      } catch (err) {
        throw err;
      }
    },
    acceptWhatsappNumber() {
      const x = this.item.whatsappNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.item.whatsappNumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    acceptTelephoneNumber() {
      const x = this.item.telephone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.item.telephone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    async findKeywords(query, parentId = 0) {
      this.isLoading = true;
      try {
        this.keywords = await this.loadCodes(6, query, parentId);
      } catch (err) {
        console.error("findKeywords", err);
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    this.role = this.$store.state.auth.usr.role;
    this.isAdminOrCustomer = this.role === 'admin' || this.role === 'customer';
    // await this.loadItem();
    const item = { ...this.item };
    await this.findProvince();
    await this.findDistrict();
    await this.findBuilding();
    await this.findCompanyScopes();
    await this.findCompanyTypes();
    await this.findSectors();
    if (this.role === 'customer') {
      await this.findSubUsers();
    }
    if (this.item._id) {
      this.item.district = {
        value: item.district._id,
        label: item.district.name,
      };
      this.item.building = {
        value: item.building._id,
        label: item.building.name,
      };
      this.item.sector = { value: item.sector._id, label: item.sector.name };
      this.item.province = {
        value: item.province._id,
        label: item.province.name,
      };

      this.item.company_scopes = item.company_scopes.map((x) => ({
        label: x.name,
        value: x._id,
      }));

      this.item.sector = item.sector.map((x) => ({
        label: x.name,
        value: x._id,
      }));

      this.item.type = {
        label: item.type.name,
        value: item.type._id,
      };

      if (item.top_view_start_date)
        this.item.top_view_start_date = moment(item.top_view_start_date).format(
          "DD/MM/YYYY"
        );

      if (item.special_view_start_date)
        this.item.special_view_start_date = moment(
          item.special_view_start_date
        ).format("DD/MM/YYYY");

      this.item.socialNetworks = [...(item.socialNetworks || [])];

      if (this.item.logo) {
        this.companyLogoUrl = `${process.env.VUE_APP_API_URL}/multimedia/${this.item.logo}`;
      } else {
        this.companyLogoUrl = "";
      }

      this.item.keywords = item.keywords.map((x) => ({
        label: x.name,
        value: x._id
      }));

      let tempArray = this.item.keywords.map((x) =>
        x.label
      );
      await this.findKeywords(tempArray);
    } else {
      await this.findKeywords();
    }
  },
  async created() {
    this.$validator.extend("secildi", {
      getMessage: (field) => `${field} seçiniz`,
      validate: (value) => {
        if (parseInt(value) === 0) return false;
        return true;
      },
    });
  },
};
