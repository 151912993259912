import BuildingService from "../../../services/building.service";
import DistrictService from "../../../services/district.service";
import ProvinceService from "../../../services/province.service";

export default {
	name: "Buildings",
	data() {
		return {
			buildings: [],
			tableFields: [
				{ key: "select", label: "" },
				{ key: "name", label: "Bina Adı", _style: "min-width:200px" },
				{ key: "provinceName", label: "İl Adı", _style: "min-width:100px;" },
				{ key: "districtName", label: "İlçe Adı", _style: "min-width:100px;" },
				{ key: "companyCount", label: "Şirket Sayısı", _style: "min-width:100px;" },
				{
					key: "actions",
					label: "",
					_style: "width:1%",
					sorter: false,
					filter: false,
				},
			],
			details: [],
			provinces: [],
			districts: [],
			itemsPerPage: 10,
			collapseDuration: 0,
			listing: true,
			itemsPerPageSelectSettings: { label: "Sayı:", values: [10, 20, 50, 100] },
			tableFilter: {
				label: "Filtrele",
				placeholder: "Aranacak kelimeyi girin",
				// external: true,
				// lazy: true,
			},
			deleteModal: false,
			buildingToBeAdd: {
				name: "",
				district: 0,
				province: 0,
			},
			itemToBeDelete: {},
			editing: false,
			toasts: [],
			noItemsViewSettings: {
				noResults: "Filtrelenecek sonuç yok",
				noItems: "Görüntülenecek bilgi yok",
			},
			activePage: 1,
			totalNumberOfPages: 1,
			itemsPerPageSettings: { label: "Sayı", values: [10, 20, 50, 100] },
			search: {
				name: '',
				active: 0,
				order: ''
			},
			tableFilterText: '',
			allItemsSelected: false,
			selectedItems: [],
			batchDeleteModal: false,
			message: ''
		};
	},
	methods: {
		toggleDetails(item, index) {
			this.$set(this.buildings[index], "_toggled", !item._toggled);
			this.collapseDuration = 300;
			this.$nextTick(() => {
				this.collapseDuration = 0;
			});
		},
		async editItem(building) {
			this.provinces = await this.loadProvinces();
			this.districts = await this.loadDistricts({
				province: building.provinceId,
			});
			this.buildingToBeAdd = {
				name: building.name,
				district: building.districtId,
				province: building.provinceId,
				id: building.id,
			};
			this.listing = false;
			this.editing = true;
		},
		showDeleteModal(building) {
			this.deleteModal = true;
			this.itemToBeDelete = building;
		},
		async showAddBuilding() {
			this.provinces = await this.loadProvinces();
			this.listing = false;
			this.buildingToBeAdd = {
				name: "",
				district: 0,
				province: 0,
			};
		},
		async saveBuilding() {
			try {
				const isValid = await this.$validator.validateAll();
				if (!isValid) {
					return;
				}

				if (!this.editing) {
					const res = await BuildingService.post({
						name: this.buildingToBeAdd.name,
						district: this.buildingToBeAdd.district,
						province: this.buildingToBeAdd.province,
					});
					await this.loadItems();
					this.listing = true;
					this.editing = false;
					this.$toast.success({
						title: 'Başarılı',
						message: 'Yeni bina eklendi',
					});
				} else {
					const res = await BuildingService.put(this.buildingToBeAdd.id, {
						name: this.buildingToBeAdd.name,
						district: this.buildingToBeAdd.district,
						province: this.buildingToBeAdd.province,
					});
					await this.loadItems();
					this.listing = true;
					this.editing = false;
					this.$toast.success({
						title: 'Başarılı',
						message: 'Bina güncellendi'
					});
				}
			} catch (err) {
				this.message =
					(err.response && err.response.data) || err.message || err.toString();
			}
		},
		hideAddBuilding() {
			this.listing = true;
			this.editing = false;
			this.message = "";
		},
		async loadItems(params) {
			this.selectedItems = [];
			try {
				const res = await BuildingService.get({
					page: this.activePage,
					skip: (this.activePage - 1) * this.itemsPerPage,
					limit: this.itemsPerPage,
					...params
				});
				this.buildings = res.data.results.map((x) => ({
					id: x._id,
					name: x.name,
					districtName: x.district?.name,
					districtId: x.district?._id,
					provinceName: x.province?.name,
					provinceId: x.province?._id,
					companyCount: x.companyCount,
				}));
				this.totalNumberOfPages = res.data.pageCount;
			} catch (err) {
				throw err;
			}
		},
		async paginationChage(e) {
			this.activePage = 1;
			this.itemsPerPage = e;
			await this.loadItems();
		},
		async loadProvinces() {
			try {
				const resProvince = await ProvinceService.get();
				const provinces = resProvince.data.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				return [{ value: 0, label: "İl seçiniz" }, ...provinces];
			} catch (err) {
				console.error("Buildings/loadProvinces error: ", err);
				return [];
			}
		},
		async loadDistricts(params) {
			try {
				const resDistrict = await DistrictService.get(params);
				const districts = resDistrict.data.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				return [{ value: 0, label: "İlçe seçiniz" }, ...districts];
			} catch (err) {
				console.error("Buildings/loadDistricts error:", err);
				return [];
			}
		},
		async provinceChange(event) {
			const districts = await this.loadDistricts({
				province: event.target.value,
			});
			this.districts = [...districts];
			this.$forceUpdate();
		},
		async serverSearch(e) {
			this.activePage = 1;
			await this.loadItems({ name: this.search.name })
		},
		check(item) {
			const val = Boolean(item._selected);
			this.buildings = this.buildings.map(building => building.id === item.id ? ({...building, _selected: !val}) : building);
			this.getSelectedItems();
		},
		checkAll(checked) {
			this.buildings.forEach((item) => {
				this.$set(item, "_selected", checked);
			});
			this.getSelectedItems();
		},
		getSelectedItems() {
			this.selectedItems = this.buildings.filter((x) => {
				if (x._selected) return x.id;
			});
		},
		async deleteItem() {
			try {
				await BuildingService.delete([this.itemToBeDelete.id]);
				await this.loadItems();
			} catch (err) {
				console.error(err);
				this.$toast.error({
					title: 'Hata',
					message: err.response.data.error
				});
			}
			this.deleteModal = false;
		},
		async batchDeleteItems() {
			try {
				const ids = this.selectedItems.map(x => x.id);
				await BuildingService.delete(ids);
				await this.loadItems();
				this.batchDeleteModal = false;
				this.selectedItems = [];
				this.allItemsSelected = false;
				this.activePage = 1;
			} catch (err) {
				console.error(err);
				this.$toast.error({
					title: 'Hata',
					message: err.response.data.error
				});
			}
		},
		showBatchDeleteModal() {
			this.batchDeleteModal = true;
		}
	},
	async mounted() {
		try {
			await this.loadItems();
			this.provinces = this.loadProvinces();
		} catch (err) {
			console.error("err", err);
		}
	},
	watch: {
		tableFilterText: async function (n, o) {
			this.activePage = 1;
			await this.loadItems({ name: n });
		},
		selectedItems(n, o) {
			this.allItemsSelected = (n.length > 0 && (n.length === this.buildings.length));
		}
	},
	created() {
		this.$validator.extend(
			'secildi', {
			getMessage: field => `${field} seçiniz`,
			validate: (value) => {
				if (value === 0) return false;
				return true;
			}
		});
	}
};
