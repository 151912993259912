import axios from 'axios';

const apiUrl = process.env.VUE_APP_API_URL;

class AuthService {
  async login(user) {
    try {
      const res = await axios.post(`${apiUrl}/login`, {
        email: user.email,
        password: user.password
      });

      if (res.data.token) {
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('application', res.data.application);
        localStorage.setItem('usr', JSON.stringify(res.data.usr));
      }

      return res.data
    } catch (err) {
      throw err;
    }
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('application');
    localStorage.removeItem('usr');
  }

  async register(user) {
    try {
      return await axios.post(`${apiUrl}/user`, user);
    } catch (err) {
      throw err;
    }
  }
}

export default new AuthService();