
import userServices from '../../../services/user.service.js';
import Multimedia from "../../../components/Multimedia/Multimedia.vue";

export default {
	name: "Profile",
	components: {
		Multimedia
	},
	data() {
		return {
			user: {
				password: "",
				telephone: "",
				avatar_path: "",
			},
		};
	},
	computed: {},
	async mounted() {
		try {
			const _data = await userServices.get();
			this.user = { ...this.user, ..._data.data, password: "" };
		} catch (err) {
			console.error("error:", err);
		}
	},
	methods: {
		async save() {
			try {
				const user = {
					...this.user,
					package: this.user.package._id,
				}
				await userServices.put(this.user._id, user);
				this.$toast.success({
					title: 'Profile Güncelleme',
					message: 'Profile güncelleme işlemi başarılı'
				});
			} catch (err) {
				this.message =
					(err.response && err.response.data) || err.message || err.toString();
				console.error(this.message);
				this.$toast.error({
					title: 'Profile Güncelleme',
					message: 'Profile güncelleme işlemi başarısız'
				});
			}
		},
		selectMultimedia(image, whereIs) {
			if (whereIs === "avatar") {
				this.user.avatar_path = `${process.env.VUE_APP_API_URL}/multimedia/${image.id}`;
				this.$forceUpdate();
			}
		},
		openMultimedia() {
			this.$refs.mmodal.show("avatar", 0);
		},
		acceptNumber() {
			var x = this.user.telephone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
			this.user.telephone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
		}
	}
}