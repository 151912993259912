import CrudService from './crud.service';
import axios from "axios";
import authHeader from "./auth-header";
class RealEstateService extends CrudService {
	constructor() {
		super('realEstate');
	}
	async delete(data) {
		return axios.delete(`${this.apiUrl}/${this.endpoint}`, {
			data, params: {
				application: this.application
			}, headers: authHeader()
		});
	}
}

export default new RealEstateService();