import packageService from "../../../services/packages.service";
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput.vue'
import moment from "moment";

export default {
	name: "AddPackage",
	components: {
		CurrencyInput
	},
	data() {
		return {
			isLoading: false,
			modalVisible: false,
			item: {
				productCount: null,
				name: null,
				default: null,
				companyCount: null
			},
			loadItemsFunc: Function
		};
	},
	async mounted() {
	},
	methods: {
		show({ loadItemsFunc, item }) {
			this.loadItemsFunc = loadItemsFunc;
			this.item = { ...item };
			this.modalVisible = true;
		},
		cancel() {
			this.item = {
				productCount: null,
				name: null,
				default: null
			};
			this.modalVisible = false;
			this.errors.clear();
		},
		async save() {
			const isValid = await this.$validator.validateAll();
			if (!isValid) {
				return;
			}
			try {
				const item = { ...this.item };
				if (!item._id) {
					await packageService.post(item)
				} else {
					await packageService.put(item._id, item);
				}
				await this.loadItemsFunc();
				this.modalVisible = false;
				this.errors.clear();
			} catch (err) {
				console.error("errr", err);
				this.message =
					(err.response && err.response.data) ||
					err.message ||
					err.toString() ||
					err.error;
			}
		}
	},
	created() {
		this.$validator.extend(
			'secildi', {
			getMessage: field => `${field} seçiniz`,
			validate: (value) => {
				console.log(value);
				if (!value.value) return false;
				return true;
			}
		});
	}
}