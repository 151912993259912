
import Vue from "vue"
import App from "./App.vue"
import { router } from "./router";
import store from "./store/index.js";
import CoreuiVue from "@coreui/vue";
import VeeValidate from "vee-validate";
import Multiselect from "vue-multiselect";
import * as VueGoogleMaps from 'vue2-google-maps';
import { VueEditor } from "vue2-editor";
import messages from 'vee-validate/dist/locale/tr';
import vSelect from "vue-select";
import mdiVue from 'mdi-vue/v2'
import * as mdijs from '@mdi/js'
// import Ionicons from 'vue-ionicons/dist/ionicons';
import Datepicker from "vue2-datepicker";
import CxltToastr from 'cxlt-vue2-toastr'

import 'vue2-datepicker/index.css';
import "vue-multiselect/dist/vue-multiselect.min.css";
import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'

import { iconsSet as icons } from "./assets/icons/icons";
Vue.config.productionTip = false;
Vue.config.ignoredElements = ['ion-icon'];

Vue.config.devtools = true;

// Vue.use(Ionicons);
Vue.use(CoreuiVue);
Vue.use(VeeValidate, {
  // errorBagName: 'vErrors', 
  fieldsBagName: 'fieldsBags',
});
Vue.use(VueEditor);
Vue.use(mdiVue, {
  icons: mdijs
});
Vue.component("multiselect", Multiselect)
Vue.component("v-select", vSelect);
Vue.component("DatePicker", Datepicker)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: "places",
  }
});
const toastrConfigs = {
  position: 'top right',
  showDuration: 3000,
  // hideDuration: 15000,
  closeButton: true,
}
Vue.use(CxltToastr, toastrConfigs)

new Vue({
  router,
  store,
  icons,
  render: h => h(App),
  mounted() {
    this.$validator.localize('tr', messages);
  }
}).$mount('#app');