import { render, staticRenderFns } from "./DeleteSector.html?vue&type=template&id=502cabb5&"
import script from "./DeleteSector.js?vue&type=script&lang=js&"
export * from "./DeleteSector.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports