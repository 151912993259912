import companyService from "../../../services/company.service";
import worklistService from "../../../services/worklist.service";
import { VueEditor } from "vue2-editor";
import moment from "moment";

export default {
	name: "AddWorklist",
	components: {
		VueEditor,
	},
	data() {
		return {
			isLoading: false,
			modalVisible: false,
			item: {
				province: null,
				name: null
			},
			companies: [],
			loadItemsFunc: Function
		};
	},
	async mounted() {
		try {
			const res = await companyService.get({
				page: 1
			});
			const companies = res.data.results.map((x) => ({
				value: x._id,
				label: x.name,
			}));
			this.companies = [{ value: 0, label: "Firma seçiniz" }, ...companies];
		} catch (err) {
			console.error("addWorklist/mounted error:", err)
		}
	},

	methods: {
		show({ loadItemsFunc, item }) {
			this.loadItemsFunc = loadItemsFunc;
			this.item = { ...item }
			if (this.item._id) {
				this.item.company = { value: this.item.company._id, label: this.item.company.name };
			}
			this.modalVisible = true;
		},
		cancel() {
			this.item = {
				province: null,
				name: null
			};
			this.modalVisible = false;
			this.errors.clear();
		},
		async save() {
			const isValid = await this.$validator.validateAll();
			if (!isValid) {
				return;
			}
			try {
				const item = { ...this.item };
				item.date = moment(item.date, "DD/MM/YYYY");
				item.company = item.company.value;
				if (!item._id) {
					await worklistService.post(item)
				} else {
					await worklistService.put(item._id, item);
				}
				await this.loadItemsFunc();
				this.modalVisible = false;
				this.errors.clear();
			} catch (err) {
				console.error("errr", err);
				this.message =
					(err.response && err.response.data) ||
					err.message ||
					err.toString() ||
					err.error;
			}
		},
		async findCompany(query) {
			this.isLoading = true;
			try {
				const res = await companyService.get({
					page: 1,
					adminView: true,
					name: query
				});
				const companies = res.data.results.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				this.companies = [...companies];
			} catch (err) {
				throw err;
			}
			this.isLoading = false;
		},
	},
	created() {
		this.$validator.extend(
			'secildi', {
			getMessage: field => `${field} seçiniz`,
			validate: (value) => {
				console.log(value);
				if (!value.value) return false;
				return true;
			}
		});
	}
}