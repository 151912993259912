import SectorService from "../../../services/sector.service";
import Iconselector from "../../../components/IconSelector/IconSelector.vue";
import Multimedia from "../../../components/Multimedia/Multimedia.vue";
import TreeTable from "../../../components/TreeTable/TreeTable.vue";

import AddSector from "./AddSector.vue";
import DeleteSector from "./DeleteSector.vue";
import BulkDeleteSector from "./BulkDeleteSector.vue";

export default {
  name: "Sectors",
  data() {
    return {
      icons: [
        {
          "icon": "add-circle",
          "title": "Add Circle"
        }
      ],
      sectors: [],
      tableFields: [
        { key: "select", label: "", _style: 'min-width:40px' },
        { key: "toggle", label: "", filter: false, _style: 'min-width:70px' },
        { key: "name", label: "Sektör Adı", _style: "min-width:300px" },
        { key: "active", label: "Aktif", _style: "min-width:100px" },
        { key: "order", label: "Sıra No", _style: "min-width:100px" },
        { key: "companyCount", label: "Şirket Sayısı", _style: "min-width: 100px", filter: false },
        {
          key: "actions",
          label: "",
          sorter: false,
          filter: false,
        },
      ],
      details: [],
      itemsPerPage: 10,
      collapseDuration: 0,
      listing: true,
      tableFilter: {
        label: "Filtrele",
        placeholder: "Aranacak kelimeyi girin",
        external: true,
        lazy: true,
      },
      editing: false,
      noItemsViewSettings: {
        noResults: "Filtrelenecek sonuç yok",
        noItems: "Görüntülenecek bilgi yok",
      },
      itemsPerPageSettings: { label: "Sayı", values: [10, 20, 50, 100] },
      activePage: 1,
      totalNumberOfPages: 1,
      emptyItem: {
        name: null,
        order: 0,
        icon: null,
        parent_id: null,
        active: true,
        view_on_tab: false,
        multimedia: null
      },
      provinces: [],
      w: "3em",
      h: "3em",
      selectedItems: [],
      allItemsSelected: false,
      tableFilterText: '',
      search: {
        name: '',
        active: 0,
        order: ''
      },
      isLoading: false,
    };
  },
  components: {
    Iconselector,
    Multimedia,
    AddSector,
    DeleteSector,
    BulkDeleteSector,
    TreeTable,
  },
  async mounted() {
    try {
      await this.loadItems();
    } catch (err) {
      console.error("error:", err);
    }
  },
  methods: {
    async paginationChage(e) {
      this.activePage = 1;
      this.itemsPerPage = e;
      await this.loadItems();
    },
    async loadItems(params) {
      try {
        this.selectedItems = [];
        this.allItemsSelected = false;
        const res = await SectorService.get({
          // onlyParentSectors: true,
          page: this.activePage,
          skip: (this.activePage - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
          adminView: true,
          ...params
        });
        this.totalNumberOfPages = res.data.pageCount;
        const sectors = res.data.results;
        this.sectors = sectors.map((x) => ({
          _id: x._id,
          name: x.name,
          order: x.order,
          icon: x.icon,
          parent_id: x.parent_id,
          active: x.active,
          view_on_tab: x.view_on_tab,
          multimedia: x.multimedia,
          companyCount: x.companyCount,
          parentSector: x.parentSector,
          _selected: false,
          children: x.children,
          _toggled: false,
          collapseDuration: 0
        }));

      } catch (err) {
        console.error("err:", err);
      }
    },
    getBadge(active) {
      return active ? "success" : "danger";
    },
    deleteItem(item) {
      this.$refs.deleteModal.show({ item: { name: item.name, _id: item._id, type: item.type } });
    },
    check(item) {
      const val = Boolean(item._selected);
      this.sectors = this.sectors.map(sector => sector._id === item._id ? ({ ...sector, _selected: !val }) : sector);
      this.getSelectedItems();
    },
    checkAll(checked) {
      this.sectors.forEach((item) => {
        this.$set(item, "_selected", checked);
      });
      this.getSelectedItems();
    },
    getSelectedItems() {
      this.selectedItems = this.sectors.filter((x) => {
        if (x._selected) return x._id;
      });
    },
    async serverSearch(e) {
      this.activePage = 1;
      const active = ["", "true", "false"];
      await this.loadItems({ name: this.search.name, active: active[this.search.active], order: this.search.order })
    },
    toggleDetails(item) {
      this.$set(item, '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0 })
    },
    bulkDeleteItems() {
      this.$refs.bulkDeleteModal.show({ items: this.selectedItems });
    },
    addItem() {
      this.$refs.addModal.show({ item: { ...this.emptyItem } })
    },
    editItem(item) {
      const existingItem = { ...item };
      this.$refs.addModal.show({
        loadItemsFunc: this.loadItems,
        item: existingItem
      })
    }
  },
  watch: {
    tableFilterText: async function (n, o) {
      this.activePage = 1;
      await this.loadItems({ name: n });
    },
    selectedItems(n, o) {
      this.allItemsSelected = (n.length > 0 && (n.length === this.sectors.length));
    }
  },
};