import worklistService from "../../../services/worklist.service";

export default {
	name: "DeleteWorklist",
	data() {
		return {
			modalVisible: false,
			item: {},
			loadItemsFunc: Function
		};
	},
	methods: {
		show({ loadItemsFunc, item }) {
			this.item = { ...item };
			this.loadItemsFunc = loadItemsFunc;
			this.modalVisible = true;
		},
		cancel() {
			this.item = {
				province: null,
				name: null
			};
			this.modalVisible = false;
		},
		async _delete() {
			try {
				await worklistService.delete([this.item]);
				await this.loadItemsFunc();
				this.modalVisible = false;
				this.$toast.success({
					title: 'İş İlanı silme',
					message: 'Başarıyla silindi'
				});
			} catch (err) {
				const message =
					(err.response && err.response.data) ||
					err.message ||
					err.toString() ||
					err.error;

				this.$toast.error({
					title: 'İş İlanı silme',
					message: message.error || message
				});

				console.error('deleteWorklist/_delete error: ', err);
			}
		},
	},
}