import WorkListService from "../../../services/worklist.service";
import moment from "moment";
import AddWorklist from "./AddWorklist.vue";
import DeleteWorklist from "./DeleteWorklist.vue";
import BulkDeleteWorklist from "./BulkDeleteWorklist.vue";

export default {
	name: "Worklists",
	data() {
		return {
			isCustomer: false,
			worklists: [],
			tableFields: [
				{ key: "select", label: "" },
				{ key: "name", label: "İş İlanı Adı", _style: "min-width:200px" },
				{ key: "active", label: "Aktif", _style: "min-width:200px" },
				{ key: "date", label: "Tarih", _style: "min-width:200px" },
				{
					key: "actions",
					label: "",
					_style: "width:1%",
					sorter: false,
					filter: false,
				},
			],
			details: [],
			itemsPerPage: 10,
			collapseDuration: 0,
			listing: true,
			tableFilter: {
				label: "Filtrele",
				placeholder: "Aranacak kelimeyi girin",
				external: true,
				lazy: true,
			},
			noItemsViewSettings: {
				noResults: "Filtrelenecek sonuç yok",
				noItems: "Görüntülenecek bilgi yok",
			},
			activePage: 1,
			totalNumberOfPages: 1,
			newItem: true,
			addItemModal: false,
			emptyItem: {
				company: null,
				name: null,
				active: true,
				description: "",
				url: null,
				date: moment().format("DD/MM/YYYY"),
			},
			selectedItems: [],
			search: {
				name: '',
				active: 0,
				date: ''
			},
			allItemsSelected: false,
			itemsPerPageSettings: { label: "Sayı", values: [10, 20, 50, 100] },
			tableFilterText: '',
		};
	},
	components: {
		AddWorklist,
		DeleteWorklist,
		BulkDeleteWorklist
	},
	async mounted() {
		try {
			this.isCustomer = this.$store.state.auth.usr.role === 'customer';
			await this.loadItems();
		} catch (err) {
			console.error("error:", err);
		}
	},
	methods: {
		async paginationChage(e) {
			this.itemsPerPage = e;
			await this.loadItems();
		},
		async loadItems(params) {
			try {
				this.allItemsSelected = false;
				const res = await WorkListService.get({
					page: this.activePage,
					skip: (this.activePage - 1) * this.itemsPerPage,
					limit: this.itemsPerPage,
					company: this.companyId,
					...params
				});
				this.worklists = res.data.results.map((x) => {
					return {
						...x,
						_classes: moment(new Date()).diff(moment(x.date)) > 0 ? 'table-danger' : ''
					};
				});
				this.totalNumberOfPages = res.data.pageCount;
				this.worklists.forEach((x) => {
					x.date = moment(x.date).format("DD/MM/YYYY");
				});
			} catch (err) {
				console.error("err:", err);
			}
		},
		getBadge(active) {
			return active ? "success" : "danger";
		},
		async serverSearch(e) {
			this.activePage = 1;
			const active = ["", "true", "false"];
			await this.loadItems({ name: this.search.name, active: active[this.search.active], date: this.search.date })
		},
		getSelectedItems() {
			this.selectedItems = this.worklists.filter((x) => {
				if (x._selected) return x._id;
			});
		},
		check(item) {
			const val = Boolean(item._selected);
			this.worklists = this.worklists.map(wl => wl._id === item._id ? ({ ...wl, _selected: !val }) : wl);
			this.getSelectedItems();
		},
		checkAll(checked) {
			this.worklists.forEach((item) => {
				this.$set(item, "_selected", checked);
			});
			this.getSelectedItems();
		},
		addItem() {
			console.log(this.$refs);
			this.$refs.addModal.show({
				loadItemsFunc: this.loadItems, item: { ...this.emptyItem }
			});
		},
		editItem(item) {
			const existingItem = { ...item };
			this.$refs.addModal.show({
				loadItemsFunc: this.loadItems, item: existingItem
			});
		},
		bulkDeleteItems() {
			this.$refs.bulkDeleteModal.show({ loadItemsFunc: this.loadItems, items: this.selectedItems });
		},
		deleteItem(item) {
			this.$refs.deleteModal.show({ loadItemsFunc: this.loadItems, item: { name: item.name, _id: item._id, company: item.company._id } });
		},
	},
};
