<template>
  <div>
    <tr :id="node._id">
      <td style="min-width: 60px !important;"></td>
      <td class="py-2" style="min-width: 70px" @click="toggleChildren">
        <CIcon
          v-if="hasChildren"
          :name="toggleChildrenIcon"
          style="cursor: pointer"
        ></CIcon>
      </td>
      <td style="min-width: 310px">
        <span :style="nodeMargin">
          <a :href="`${baseLink}${node._id}`" target="_blank">{{
            node.name
          }}</a>
        </span>
      </td>
      <td style="min-width: 320px">
        <CBadge :color="getBadge(node.active)">
          {{ node.active ? "Evet" : "Hayır" }}
        </CBadge>
      </td>
      <td style="min-width: 320px">{{ node.order }}</td>
      <td style="min-width: 220px">{{ node.companyCount }}</td>
      <td>
        <button
          type="button"
          class="btn btn-danger btn-sm"
          @click="deleteFunc(node)"
        >
          Sil
        </button>
      </td>
      <td>
        <button
          type="button"
          class="btn btn-info btn-sm"
          @click="editFunc(node)"
        >
          Düzenle
        </button>
      </td>
    </tr>

    <div v-if="hasChildren" v-show="showChildren">
      <TreeTable
        v-for="child in node.children"
        :key="child.id"
        :node="child"
        :editFunc="editFunc"
        :deleteFunc="deleteFunc"
        :spacing="spacing + 10"
        :baseLink="baseLink"
        class="tree-table"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TreeTable",
  props: {
    node: {
      type: Object,
      required: true,
    },
    spacing: {
      type: Number,
      default: 0,
    },
    editFunc: {
      type: Function,
      required: true,
    },
    deleteFunc: {
      type: Function,
      required: true,
    },
    baseLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showChildren: false,
    };
  },
  mounted(){
  },
  computed: {
    nodeMargin() {
      return {
        "margin-left": `${this.spacing}px`,
      };
    },
    hasChildren() {
      const { children } = this.node;
      return children && children.length > 0;
    },
    toggleChildrenIcon() {
      return this.showChildren ? "cilMinus" : "cilPlus";
    },
  },
  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren;
    },
    getBadge(active) {
      return active ? "success" : "danger";
    },
  },
};
</script>
