import ApplicationService from ".../../../services/application.service";
import Multimedia from "../../../components/Multimedia/Multimedia.vue";
// import CompanyService from "../../../services/company.service";
import companyService from "../../../services/company.service";

export default {
	name: "Application",
	components: {
		Multimedia
	},
	data() {
		return {
			itemToBeAdd: {
				_id: undefined,
				name: undefined,
				logo_path: undefined,
				top_view_color: undefined,
				special_view_color: undefined,
				companyId: undefined,
				banner: undefined,
				drawerLogo: undefined,
				slideVideo: undefined,
			},
			companies: [],
			toasts: [],
			images: [],
			bannerUrl: undefined,
			drawerLogoUrl: undefined,
			isLoading: false,
			slideVideoUrl: undefined,
		};
	},
	computed: {},
	async mounted() {
		try {
			await this.findCompany();
			const _data = await ApplicationService.get({
				adminView: true
			});
			this.itemToBeAdd = _data.data[0];
			if (this.itemToBeAdd.company) {
				this.itemToBeAdd.company = {
					label: this.itemToBeAdd.company.name,
					value: this.itemToBeAdd.company._id
				}
			}
			this.itemToBeAdd.top_view_color = '#' + this.itemToBeAdd.top_view_color;
			this.itemToBeAdd.special_view_color = '#' + this.itemToBeAdd.special_view_color;
			this.bannerUrl = `${process.env.VUE_APP_API_URL}/multimedia/${this.itemToBeAdd.banner}`;
			this.drawerLogoUrl = `${process.env.VUE_APP_API_URL}/multimedia/${this.itemToBeAdd.drawerLogo}`;
		} catch (err) {
			console.error("error:", err);
		}
	},
	methods: {
		openSlideVideoMultimedia() {
			this.$refs.mmodal.show("slideVideo", 0);
		},
		openMultimedia() {
			this.$refs.mmodal.show("application", 0);
		},
		openBannerMultimedia() {
			this.$refs.mmodal.show("applicationBanner", 0);
		},
		openDrawerLogoMultimedia() {
			this.$refs.mmodal.show("applicationDrawerLogo", 0);
		},
		selectMultimedia(image, whereIs) {
			if (whereIs === "application") {
				this.itemToBeAdd.logo_path = `${process.env.VUE_APP_API_URL}/multimedia/${image.id}`;
				this.$forceUpdate();
			}
			if (whereIs === "applicationBanner") {
				this.bannerUrl = `${process.env.VUE_APP_API_URL}/multimedia/${image.id}`;
				this.itemToBeAdd.banner = image.id;
				this.$forceUpdate();
			}
			if (whereIs === "applicationDrawerLogo") {
				this.drawerLogoUrl = `${process.env.VUE_APP_API_URL}/multimedia/${image.id}`;
				this.itemToBeAdd.drawerLogo = image.id;
				this.$forceUpdate();
			}
			if (whereIs === "slideVideo") {
				this.itemToBeAdd.slideVideo = image.id;
				this.$forceUpdate();
			}
		},
		async save() {
			try {
				const isValid = await this.$validator.validateAll();
				if (!isValid) {
					console.error(this.$validator.errors.all());
					return;
				}
				const _temp = { ...this.itemToBeAdd };
				_temp.top_view_color = _temp.top_view_color.split('#')[1];
				_temp.special_view_color = _temp.special_view_color.split('#')[1];
				_temp.company = _temp.company.value;
				await ApplicationService.put(_temp._id, _temp);
				this.$toast.success({
					title: 'Uygulama Ayarları',
					message: 'Uygulama güncellendi'
				});
			} catch (err) {
				this.message =
					(err.response && err.response.data) || err.message || err.toString();
				console.error(this.message);
				this.$toast.error({
					title: 'Uygulama Ayarları',
					message: this.message.error || this.message
				});
			}
		},
		async findCompany(query) {
			this.isLoading = true;
			try {
				const res = await companyService.get({
					page: 1,
					adminView: true,
					name: query
				});
				const companies = res.data.results.map((x) => ({
					value: x._id,
					label: x.name,
				}));
				this.companies = [...companies];
				console.log(this.companies);
			} catch (err) {
				throw err;
			}
			this.isLoading = false;
		},
	}
}