const state = {
	sidebarShow: 'responsive',
	sidebarMinimize: false
};

const actions = {
	toggleSidebarDesktop({ commit }, state) {
		commit('toggleSidebarDesktop');
	},
	toggleSidebarMobile({ commit }, state) {
		commit('toggleSidebarMobile')
	}
};

const mutations = {
	toggleSidebarDesktop(state) {
		const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
		state.sidebarShow = sidebarOpened ? false : 'responsive'
	},
	toggleSidebarMobile(state) {
		const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
		state.sidebarShow = sidebarClosed ? true : 'responsive'
	},
	set(state, [variable, value]) {
		state[variable] = value
	}
}

export const coreui = {
	namespaced: true,
	state,
	actions,
	mutations
};