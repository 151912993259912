import { mdiClockMinusOutline } from "@mdi/js";
import { gmapApi } from 'vue2-google-maps'

export default {
	name: "GoogleMap",
	data() {
		return {
			center: { lat: 38.423734, lng: 27.142826, address: "İzmir, Turkey" },
			currentPlace: null,
			markers: [],
			places: [],
		};
	},
	mounted() {
		this.geolocate();
		this.currentPlace = { formatted_address: this.addressInput };
	},
	methods: {
		dragEnd(marker) {
			this.$emit("selectPosition", marker.latLng.lat(), marker.latLng.lng());
		},
		setPlace(place) {
			this.currentPlace = place;
			this.addMarker();
		},
		addMarker() {
			if (this.currentPlace) {
				const marker = {
					lat: this.currentPlace.geometry.location.lat(),
					lng: this.currentPlace.geometry.location.lng(),
				};
				if (this.markers.length > 0) {
					this.markers[0] = { position: marker };
				} else {
					this.markers.push({ position: marker });
				}
				if (this.places.length > 0) {
					this.places[0] = this.currentPlace;
				} else {
					this.places.push(this.currentPlace);
				}
				this.latInput = marker.lat;
				this.lngInput = marker.lng;
				this.addressInput = this.currentPlace.formatted_address;
				this.center = marker;
				this.currentPlace = null;
			}
		},
		async gotoLocation() {
			const marker = { lat: parseFloat(this.latInput), lng: parseFloat(this.lngInput) };
			if (this.markers.length > 0) {
				this.markers[0] = { position: marker };
			} else {
				this.markers.push({ position: marker });
			}
			this.center = { lat: parseFloat(this.latInput), lng: parseFloat(this.lngInput) };
			try {
				this.addressInput = await this.getPlace(marker);
			} catch (err) {
				console.error(err);
			}
		},
		geolocate: function () {
			const marker = { lat: parseFloat(this.latInput), lng: parseFloat(this.lngInput) };
			this.markers.push({ position: marker });
			this.center = { lat: parseFloat(this.latInput), lng: parseFloat(this.lngInput) };
		},
		getPlace(marker) {
			return new Promise((resolve, reject) => {
				var geocoder = new window.google.maps.Geocoder();
				var latLng = {
					lat: parseFloat(marker.lat),
					lng: parseFloat(marker.lng)
				};

				geocoder.geocode({ 'location': latLng }, function (results, status) {
					if (status === 'OK') {
						if (results[0]) {
							resolve(results[0].formatted_address);
						} else {
							reject('Adres bulunamadı');
						}
					} else {
						reject('Geocoder failed due to: ' + status);
					}
				});
			});
		}
	},
	computed: {
		latInput: {
			get: function () {
				return this.lat;
			},
			set: function (value) {
				this.$emit("update:lat", value);
			},
		},
		lngInput: {
			get: function () {
				return this.lng;
			},
			set: function (value) {
				this.$emit("update:lng", value);
			},
		},
		addressInput: {
			get: function () {
				return this.address;
			},
			set: function (value) {
				this.$emit("update:address", value);
			},
			google: gmapApi

		}
	},
	props: ["lat", "lng", "address"],
};
