<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <h1>Kayıt olun</h1>
              <p class="text-muted">Hesabınızı oluşturun</p>
              <CRow>
                <CCol sm="6">
                  <CInput label="Firma Ünvanı" v-model="user.companyName" type="text" id="companyName" placeholder="Firma Ünvanı" v-validate="'required'" data-vv-name="companyName" data-vv-as="Firma Ünvanı" :horizontal="true" />
                  <CAlert v-if="errors.has('companyName')" color="danger">
                    {{ errors.first("companyName") }}
                  </CAlert>
                </CCol>
                <CCol sm="6">
                  <CInput label="Yetkili" v-model="user.authorizedName" type="text" id="authorizedName" placeholder="Yetkili Adı ve Soyadı" v-validate="'required'" data-vv-name="authorizedName" data-vv-as="Yetkili Adı" :horizontal="true" />
                  <CAlert v-if="errors.has('authorizedName')" color="danger">
                    {{ errors.first("authorizedName") }}
                  </CAlert>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput label="Vergi No" v-model="user.taxNo" type="text" id="taxNo" placeholder="Vergi No." v-validate="'required'" data-vv-name="taxNo" data-vv-as="Vergi No." :horizontal="true" />
                  <CAlert v-if="errors.has('taxNo')" color="danger">
                    {{ errors.first("taxNo") }}
                  </CAlert>
                </CCol>
                <CCol sm="6">
                  <CInput label="Vergi Dairesi" v-model="user.taxAdmin" type="text" id="taxAdmin" placeholder="Vergi Dairesi" v-validate="'required'" data-vv-name="taxAdmin" data-vv-as="Vergi Dairesi" :horizontal="true" />
                  <CAlert v-if="errors.has('taxAdmin')" color="danger">
                    {{ errors.first("taxAdmin") }}
                  </CAlert>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput label="EPosta" v-model="user.email" type="email" placeholder="E-Posta" v-validate="'required|email'" data-vv-name="email" data-vv-as="EPosta" :horizontal="true" />
                  <CAlert v-if="errors.has('email')" color="danger">
                    {{ errors.first("email") }}
                  </CAlert>
                </CCol>
                <CCol sm="6">
                  <div role="group" class="form-group form-row"><label for="phone" class="col-form-label col-sm-3">Telefon</label>
                    <div class="col-sm-9">
                      <input name="phone" class="form-control" v-model="user.companyPhone" type="tel" placeholder="Telefon" data-vv-name="phone" data-vv-as="phone" :horizontal="true" @input="acceptNumber" />
                    </div>
                    <CAlert v-if="errors.has('phone')" color="danger">
                      {{ errors.first("phone") }}
                    </CAlert>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput label="Şifre" v-model="user.password" type="password" placeholder="Şifre" v-validate="'required'" data-vv-name="password" data-vv-as="Şifre" :horizontal="true" ref="password" />
                  <CAlert v-if="errors.has('password')" color="danger">
                    {{ errors.first("password") }}
                  </CAlert>
                </CCol>
                <CCol sm="6">
                  <CInput label="Şifre Tekrarı" v-model="user.passwordCheck" type="password" placeholder="Şifre Tekrarı" v-validate="'required|confirmed:password'" data-vv-name="passwordCheck" data-vv-as="Şifre Tekrarı" :horizontal="true" />
                  <CAlert v-if="errors.has('passwordCheck')" color="danger">
                    {{ errors.first("passwordCheck") }}
                  </CAlert>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput label="Adres" v-model="user.companyAddress" type="text" id="address" placeholder="Adres" v-validate="'required'" data-vv-name="address" data-vv-as="Adres" :horizontal="true" />
                  <CAlert v-if="errors.has('address')" color="danger">
                    {{ errors.first("address") }}
                  </CAlert>
                </CCol>
              </CRow>
              <CRow>
                <CCol col="6">
                  <CButton color="success" class="px-4" @click="handleRegister" :disabled="loading">
                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                    <span>Hesabı Oluştur</span>
                  </CButton>
                </CCol>
                <CCol col="6" class="text-right">
                  <CButton color="link" class="px-0">Şifrenizi mi unuttunuz?</CButton>
                  <CButton color="link" class="ml-5" @click="$router.push('/login')">Giriş Yap</CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>

export default {
  name: "Register",
  data() {
    return {
      user: {
        authorizedName: "",
        authorizedSurname: "",
        email: "",
        companyAddress: "",
        companyPhone: "",
        companyName: "",
        taxNo: "",
        taxAdmin: ""
      },
      submitted: false,
      successful: false,
      loading: false,
      message: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    async handleRegister() {
      try {
        const isValid = await this.$validator.validateAll();
        this.loading = true;
        this.message = "";
        this.submitted = true;
        if (!isValid) {
          throw { message: "gerekli yerleri doldur" };
        }
        const newUser = {
          name: this.user.authorizedName,
          surname: this.user.authorizedSurname,
          email: this.user.email,
          password: this.user.password,
          companyName: this.user.companyName,
          companyAddress: this.user.companyAddress,
          companyPhone: this.user.companyPhone,
          taxNo: this.user.taxNo,
          taxAdmin: this.user.taxNo
        };
        const res = await this.$store.dispatch("auth/register", newUser);
        this.successful = true;
        this.loading = false;
        this.$router.push('/login');
      } catch (err) {
        console.error(err);
        this.loading = false;
        this.successful = true;
        this.message =
          (err.response && err.response.data) || err.message || err.toString();
      }
    },
    acceptNumber() {
      var x = this.user.companyPhone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.user.companyPhone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }
  },
};
</script>
