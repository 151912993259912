import icons from '../../icons.json';

export default {
	data() {
		return {
			iconSelectorModal: false,
			icons: icons,
			whereIs: "",
            w: "60px",
            h: "60px"
		};
	},
	name: "Iconselector",
	methods: {
		selectIcon(iconName) {
			this.$emit("selectIcon", iconName, this.whereIs);
			this.iconSelectorModal = false;
		},
		show(whereIs) {
            console.log('test');
			this.iconSelectorModal = true;
            this.whereIs = whereIs;
		}
    },
    async mounted() {
    }
};
