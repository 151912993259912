<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Giriş yapın</h1>
                  <p class="text-muted">Hesabınıza giriş yapın</p>
                  <CInput
                    v-model="user.email"
                    v-validate="'required'"
                    name="email"
                    placeholder="Eposta adresiniz"
                    autocomplete="username email"
                    prepend="@"
                    v-on:keypress.enter="handleLogin"
                  >
                  </CInput>
                  <CInput
                    v-model="user.password"
                    v-validate="'required'"
                    name="password"
                    placeholder="Şifreniz"
                    type="password"
                    autocomplete="curent-password"
                    v-on:keypress.enter="handleLogin"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-lock-locked" />
                    </template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton
                        color="primary"
                        class="px-4"
                        @click="handleLogin"
                        :disabled="loading"
                      >
                        <span
                          v-show="loading"
                          class="spinner-border spinner-border-sm"
                        ></span>
                        <span>Giriş yap</span>
                      </CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0"
                        >Şifrenizi mi unuttunuz?</CButton
                      >
                      <CButton
                        color="link"
                        class="ml-5"
                        @click="$router.push('/register')"
                        >Kayıt ol</CButton
                      >
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="12" class="mt-3">
                      <CAlert v-if="message" color="danger">
                        {{ message }}
                      </CAlert>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import User from "../../models/user";

export default {
  name: "Login",
  data() {
    return {
      user: new User("", "", "", ""),
      loading: false,
      message: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    async handleLogin() {
      try {
        this.loading = true;
        const isValid = await this.$validator.validateAll();
        if (!isValid) {
          this.loading = false;
          throw { message: "email ve şifrenizi giriniz" };
        }

        if (this.user.email && this.user.password) {
          await this.$store.dispatch("auth/login", this.user);
          if (this.$store.state.auth.usr.role === "admin") {
            this.$router.push("/dashboard");
          } else {
            this.$router.push("/customer-companies");
          }
        }
      } catch (err) {
        this.loading = false;
        this.message =
          (err.response && err.response.data) || err.message || err.toString();
      }
    },
  },
};
</script>