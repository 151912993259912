import provinceService from "../../../services/province.service";
import districtService from "../../../services/district.service";

export default {
	name: "AddDistrict",
	data() {
		return {
			modalVisible: false,
			item: {
				province: null,
				name: null
			},
			provinces: [],
			loadItemsFunc: Function
		};
	},
	async mounted() {
		try {
			const res = await provinceService.get();
			const provinces = res.data.map((x) => ({
				value: x._id,
				label: x.name,
			}));
			this.provinces = [{ value: 0, label: "İl seçiniz" }, ...provinces];
		} catch (err) {
			console.error("addDistrict/mounted error:", err)
		}
	},

	methods: {
		show({ loadItemsFunc, item }) {
			this.modalVisible = true;
			this.loadItemsFunc = loadItemsFunc;
			this.item = { ...item }
		},
		cancel() {
			this.item = {
				province: null,
				name: null
			};
			this.modalVisible = false;
			this.errors.clear();
		},
		async save() {
			const isValid = await this.$validator.validateAll();
			if (!isValid) {
				return;
			}
			try {
				if (!this.item._id) {
					await districtService.post(this.item)
				} else {
					await districtService.put(this.item._id, this.item);
				}
				await this.loadItemsFunc();
				this.modalVisible = false;
				this.errors.clear();
			} catch (err) {
				console.error("errr", err);
				this.message =
					(err.response && err.response.data) ||
					err.message ||
					err.toString() ||
					err.error;
			}
		},
	},
	created() {
		this.$validator.extend(
			'secildi', {
			getMessage: field => `${field} seçiniz`,
			validate: (value) => {
				if (parseInt(value) === 0) return false;
				return true;
			}
		});
	}
}