export default {
	name: "SocialNetworks",
	data() {
		return {
			socialNetworkTypes: [
				{
					value: 1,
					label: "Facebook",
				},
				{
					value: 2,
					label: "Instagram",
				},
				{
					value: 3,
					label: "Twitter",
				},
				{
					value: 4,
					label: "Youtube",
				},
			],
		};
	},
	props: {
		socialNetworks: [],
	},
	computed: {
		socialNetworksInput: {
			get: function () {
				return this.socialNetworks;
			},
			set: function (value) {
				this.$emit("update:socialNetworks", value);
			},
		},
	},
	mounted() {
		if (this.socialNetworksInput.length === 0) {
			this.socialNetworksInput = [{ type: 1, address: "" }, { type: 2, address: "" }, { type: 3, address: "" }, { type: 4, address: "" }]
		}
	},
	methods: {
		addItem(type = 0, address = "") {
			console.log("addItem", type, address)
			this.socialNetworksInput.push({
				type: type,
				address: address
			});
		},
		deleteItem(index) {
			this.socialNetworksInput.splice(index, 1);
		},
	},
	unmounted() {
	}
};
