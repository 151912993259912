import codeService from '../../../services/code.service';
import sectorService from '../../../services/sector.service';
import AddCode from './AddCode.vue';
import DeleteCode from './DeleteCode.vue';
import BulkDeleteCode from './BulkDeleteCode.vue';


export default {
	name: "Codes",
	data() {
		return {
			tableFields: [
				{ key: "select", label: "" },
				{ key: "name", label: "Adı", _style: "min-width:200px" },
				{ key: "typeName", label: "Tipi", _style: "min-width:200px" },
				{ key: "sectorName", label: "Sektör Adı", _style: "min-width:200px" },
				{
					key: "actions",
					label: "",
					_style: "width:1%",
					sorter: false,
					filter: false,
				},
			],
			items: [],
			itemsPerPage: 10,
			tableFilter: {
				label: "Filtrele",
				placeholder: "Aranacak kelimeyi girin",
			},
			noItemsViewSettings: {
				noResults: "Filtrelenecek sonuç yok",
				noItems: "Görüntülenecek bilgi yok",
			},
			itemsPerPageSettings: { label: "Sayı", values: [10, 20, 50, 100] },
			activePage: 1,
			totalNumberOfPages: 1,
			selectedItems: [],
			allItemsSelected: false,
			search: {
				type: "",
				name: null,
			},
			tableFilterText: '',
			sectors: [],
			sector: null,
		};
	},
	async mounted() {
		try {
			await this.findSector();
			await this.loadItems();
		} catch (err) {
			console.error("error:", err);
		}
	},
	methods: {
		async paginationChage(e) {
			this.itemsPerPage = e;
			await this.loadItems();
		},

		async loadItems(params) {
			try {
				this.selectedItems = [];
				const res = await codeService.get({
					page: this.activePage,
					skip: (this.activePage - 1) * this.itemsPerPage,
					limit: this.itemsPerPage,
					...this.search,
					...params
				});
				this.totalNumberOfPages = res.data.pageCount;
				const results = res.data.results;
				this.items = results.map((x) => ({
					_id: x._id,
					typeName: this.getTypeName(x.type),
					type: x.type,
					name: x.name,
					parentId: x.parentId,
					sectorName: this.getSectorName(x),
				}));
			} catch (err) {
				console.error("err:", err);
			}
		},
		async deleteItem(item) {
			this.$refs.deleteModal.show({ loadItemsFunc: this.loadItems, item: { name: item.name, _id: item._id, type: item.type } });
		},
		async editItem(item) {
			const existingItem = {
				_id: item._id,
				type: item.type,
				name: item.name,
				parentId: item.parentId
			};
			this.$refs.addModal.show({
				loadItemsFunc: this.loadItems, item: existingItem
			});
		},
		check(item) {
			const val = Boolean(item._selected);
			this.items = this.items.map(itm => itm._id === item._id ? ({ ...itm, _selected: !val }) : itm);
			this.getSelectedItems();
		},
		checkAll(checked) {
			this.items.forEach((item) => {
				this.$set(item, "_selected", checked);
			});
			this.getSelectedItems();
		},
		getSelectedItems() {
			this.selectedItems = this.items.filter(x => x._selected);
		},
		async serverSearch(e) {
			this.activePage = 1;
			await this.loadItems({ name: this.search.name, type: this.search.type })
		},
		addItem() {
			this.$refs.addModal.show({
				loadItemsFunc: this.loadItems, item: {
					_id: null,
					type: 0,
					name: null,
					parentId: null
				}
			});
		},
		bulkDeleteItems() {
			this.$refs.bulkDeleteModal.show({ loadItemsFunc: this.loadItems, items: this.selectedItems });
		},
		getTypeName(type) {
			const t = {
				1: "Faaliyet Alanı",
				2: "Firma Tipi",
				3: "Emlak Tipi",
				4: "Emlak Satış Tipi",
				5: "Ürün Kategori",
				6: "Anahtar Kelimeler"
			};
			return t[type];
		},
		async findSector(query) {
			try {
				const res = await sectorService.get({ name: query, page: 1 });
				this.sectors = res.data.results.map(x => ({
					value: x._id,
					label: x.name,
				}));
			} catch (error) {
				console.error("codes/findSector:", error);
				return [];
			}
		},
		async selectSector(selectedOption) {
			await this.loadItems({
				parentId: selectedOption.value
			});
		},
		async removeSector() {
			await this.loadItems({});
		},
		getSectorName(code) {
			console.log("code:", code);
			if (code.parentId && code.parentId.label) {
				return code.parentId.label;
			}
			return "";
		}
	},
	watch: {
		selectedItems(n, o) {
			this.allItemsSelected = (n.length > 0 && (n.length === this.items.length));
		}
	},
	components: {
		AddCode,
		DeleteCode,
		BulkDeleteCode
	}
}